// eslint-disable-next-line import/no-anonymous-default-export
export default {
	select_range_values: [
		{ label: 'Hoy', value: 0 },
		{ label: 'Últimos 7 días', value: 7 },
		{ label: 'Últimos 15 días', value: 15 },
		{ label: 'Últimos 30 días', value: 30 },
		{ label: 'Últimos 6 meses', value: 180 },
		{ label: 'Último año', value: 365 },
		{ label: 'Mostrar todo', value: -1 },
	],
};
