import React from 'react';
import { Dialog, Slide } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import ButtonPrimary from './../../components/ButtonPrimary/ButtonPrimary';
import ButtonSecondary from '../../components/buttons/ButtonSecondary';
import './css/customModal.scss';
import numberFormat from '../../../utils/numberFormat';
import calcHeightChart from '../../../utils/calcHeightChart';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalPopUp(props) {

    const body = (
        <div className="containerModal">
            <CloseIcon
                className="customModal-close"
                onClick={props.handleModal}
            />
            <h1 className="blockersPopUp-contentExclusion">{props.title ? props.title : null}</h1>

            {props.popupsName === 'SavingGrafics' ?
                (
                
                <div>
                    <div className="insuranceTime">
                        <button className={props.modeModal === '1' ? 'first-button active' : 'first-button inactive'} onClick={() => props.setModeModal('1')}>Por año</button>
                        <button className={props.modeModal === '2' ? 'second-button active' : 'second-button inactive'} onClick={() => props.setModeModal('2')}>En {props.infoModal.pendingInsurancePayments} años</button>
                    </div>
                    <div className="saveToday">
                        <div className="getlifeChart">
                            <div className="chartLabel">Tu seguro con Life5</div>
                            <div className="chartBlock" style={props.modeModal === '1' ? {height : calcHeightChart(props.infoModal.getlifePrice, parseInt(props.infoModal.actualInsurancePrice)) + 'px'} : {height : calcHeightChart(props.infoModal.getlifeCost, props.infoModal.netCost) + 'px'}}>
                                <div className="chartPrice">
                                    {props.modeModal === '1' ? (
                                        <p>{numberFormat(props.infoModal.getlifePrice, 'capital')}</p>
                                        ) : (
                                        <p>{numberFormat(props.infoModal.getlifeCost, 'capital')}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="yourBankChart">
                            <div className="chartLabel">Seguro con tu banco</div>
                            <div className="chartBlock">
                            {props.modeModal === '1' ? (
                                <div className="chartPrice">{numberFormat(props.infoModal.actualInsurancePrice, 'capital')}</div>
                            ) : (
                                <div className="chartPrice">{numberFormat(props.infoModal.netCost, 'capital')}</div>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
                
                )    
            : null}

          
            <div className="blockersPopUp-contentProps">
                {props.content ? props.content : null}
            </div>
            <div className="customModal-buttonContainer">
                {props.buttonSecondaryContent !== null && (
                    <ButtonSecondary
                        style={{ paddingTop: '40px', width: '100%' }}
                        handleChange={props.buttonSecondarySubmit}
                        content={props.buttonSecondaryContent} />
                )}
                <ButtonPrimary
                    style={{ paddingTop: '40px', marginTop: 10, width: '100%' }}
                    content={props.buttonPrimaryContent}
                    onClick={props.buttonPrimarySubmit} />
            </div>
            <div className='subText'><p>{props.subText}</p></div>
        </div>
    );

    return (
        <div>
            <Dialog
                fullWidth={false}
                maxWidth={'lg'}
                open={props.open}
                onClose={props.close}
                TransitionComponent={Transition}
            >
                {body}
            </Dialog>
        </div>
    );
}
