import "./omni-search.component.scss";
// Components
import InputGetLife from "../inputGetLife/InputGetLife";

export const OmniSearch = ({ omniValue, setOmniValue, placeholder }) => {
  const handleOmniSearch = (value) => {
    setOmniValue(value);
  };

  return (
    <>
      <div className="omni-search___content">
        <InputGetLife
          id={"name"}
          name={"name"}
          contentType={"text"}
          placeholder={placeholder}
          handleValues={handleOmniSearch}
          answer={omniValue}
        />
      </div>
    </>
  );
};
