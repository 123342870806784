import React from "react";

interface CloseProps {
  key: string;
  setClose: (value: boolean) => void;
  implementedFunction?: () => void | undefined;
  value?: any;
}

export const useCloseByKey = ({
  key,
  setClose,
  implementedFunction,
  value,
}: CloseProps) => {
  const closeFunction = (e: KeyboardEvent) => {
    if (key === e.key) {
      implementedFunction && implementedFunction();
      setClose && setClose(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("keydown", closeFunction);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeFunction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
};
