import React, { useContext } from "react";
import Phone from "./../../../assets/img/phone.svg";
import Calendar from "./../../../assets/img/calendar.svg";
import "./css/buttonPrimary.scss";
import { I18nContext } from "../../../contexts/i18n.context";

export default function ButtonPrimary(props) {
  const { disabled, type, content, onClick } = props;
  const {
    state: { translate },
  } = useContext(I18nContext);

  return (
    <>
      {props.content === translate("postStack.buttons.call") && (
        <button
          className={
            disabled === true ? "button-primary-disabled" : "button-primary"
          }
          style={{
            minWidth: "107px",
            padding: "0 16px 0 16px",
            fontSize: "12px",
          }}
          type={type ? type : ""}
          disabled={disabled ? disabled : false}
          onClick={() => (onClick ? onClick() : null)}
        >
          <div className="buttonPrimary-imgContainer">
            {content}
            <img src={Phone} alt="Llamar" />
          </div>
        </button>
      )}

      {content === translate("postStack.buttons.reserve") && (
        <button
          className={
            disabled === true ? "button-primary-disabled" : "button-primary"
          }
          style={{ fontSize: "12px" }}
          type={type ? type : ""}
          disabled={disabled ? disabled : false}
          onClick={() =>
            // eslint-disable-next-line no-undef
            Calendly.initPopupWidget({
              url: "https://calendly.com/oscar-getlife",
            })
          }
        >
          <div className="buttonPrimary-imgContainer">
            {content}
            <img src={Calendar} alt="Reservar cita" />
          </div>
        </button>
      )}

      {content === translate("postStack.buttons.continue") && (
        <button
          className={
            disabled === true ? "button-primary-disabled" : "button-primary"
          }
          type={type ? type : ""}
          disabled={disabled ? disabled : false}
          onClick={() => (onClick ? onClick() : null)}
        >
          {content}
        </button>
      )}

      {content === translate("incidents.form.button") && (
        <button
          className={
            disabled === true ? "button-primary-disabled" : "button-primary"
          }
          type={type ? type : ""}
          disabled={disabled ? disabled : false}
          onClick={() => (onClick ? onClick() : null)}
        >
          {content}
        </button>
      )}

      {content === "Enviar" && (
        <button
          className={
            disabled === true ? "button-primary-disabled" : "button-primary"
          }
          type={type ? type : ""}
          disabled={disabled ? disabled : false}
          onClick={() => (onClick ? onClick() : null)}
        >
          {content}
        </button>
      )}

      {type === "paylink" && (
        <button
          className={
            disabled === true ? "button-primary-disabled" : "button-primary"
          }
          type={type ? type : ""}
          disabled={disabled ? disabled : false}
          onClick={() => (onClick ? onClick() : null)}
        >
          {content}
        </button>
      )}
      {content === translate("register.button") && (
        <button
          style={{ width: props.width ? props.width : "" }}
          className={
            disabled === true ? "button-primary-disabled" : "button-primary"
          }
          type={type ? type : ""}
          disabled={disabled ? disabled : false}
          onClick={() => (onClick ? onClick() : null)}
        >
          {content}
        </button>
      )}

      {content === "Copiar" && (
        <button
          className={
            disabled === true ? "button-primary-disabled" : "button-primary"
          }
          type={type ? type : ""}
          disabled={disabled ? disabled : false}
          onClick={() => (onClick ? onClick() : null)}
        >
          {content}
        </button>
      )}

      {content === translate("dashboard.buttons.calculate") && (
        <button
          className={
            disabled === true ? "button-primary-disabled" : "button-primary"
          }
          type={type ? type : ""}
          disabled={disabled ? disabled : false}
          onClick={() => (onClick ? onClick() : null)}
        >
          {content}
        </button>
      )}

      {content === "Cotizar mi primer proyecto" && (
        <button
          className={
            disabled === true ? "button-primary-disabled" : "button-primary"
          }
          type={type ? type : ""}
          disabled={disabled ? disabled : false}
          onClick={() => (onClick ? onClick() : null)}
        >
          {content}
        </button>
      )}

      {content === translate("employees.buttons.newEmployee") && (
        <button
          className={
            disabled === true ? "button-primary-disabled" : "button-primary"
          }
          type={type ? type : ""}
          disabled={disabled ? disabled : false}
          onClick={() => (onClick ? onClick() : null)}
        >
          {content}
        </button>
      )}
      {content === "Detalles" ||
        (content === translate("global.edit") && (
          <button
            className={props.className}
            type={type ? type : ""}
            onClick={(e) => (onClick ? onClick(e) : null)}
          >
            {content}
          </button>
        ))}

      {content === translate("editEmployee.modal.button") && (
        <button
          className={props.className}
          type={type ? type : ""}
          onClick={(e) => (onClick ? onClick(e) : null)}
        >
          {content}
        </button>
      )}
      {content === translate("checkpoint.button.contract") && (
        <button
          className={props.className}
          type={type ? type : ""}
          onClick={(e) => (onClick ? onClick(e) : null)}
        >
          {content}
        </button>
      )}
      {content === translate("checkpoint.button.continue") && (
        <button
          className={props.className}
          type={type ? type : ""}
          onClick={(e) => (onClick ? onClick(e) : null)}
        >
          {content}
        </button>
      )}
      {content === translate("checkpoint.buttons.ipaBasic") && (
        <button
          className={props.className}
          type={type ? type : ""}
          onClick={(e) => (onClick ? onClick(e) : null)}
        >
          {content}
        </button>
      )}
      {content === translate("checkpoint.buttons.ipaPremium") && (
        <button
          className={props.className}
          type={type ? type : ""}
          onClick={(e) => (onClick ? onClick(e) : null)}
        >
          {content}
        </button>
      )}
      {content === translate("checkpoint.button.apply") && (
        <button
          className={props.className}
          type={type ? type : ""}
          onClick={(e) => (onClick ? onClick(e) : null)}
        >
          {content}
        </button>
      )}
      {(content === "Continúa con el cuestionario" ||
        content === "Descarga tu análisis completo") && (
        <button
          className={props.className}
          type={type ? type : ""}
          onClick={(e) => (onClick ? onClick(e) : null)}
        >
          {content}
        </button>
      )}
    </>
  );
}
