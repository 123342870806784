// Vendors
import { useContext, useEffect, useRef, useState } from "react";
// Context
import { ContextGetlife } from "../../../contexts/ContextGetlife";
// Styles
import "./download-item.component.scss";
// Constants
import constants from "./constants/download-item.constants";
import HttpListRepository from "../../../api/request/Lists/list.service";
import { I18nContext } from "../../../contexts/i18n.context";
import IconButton from "../../Rebranding/atoms/Buttons/IconButton/IconButton.component";
import DownloadIcon from "../../Rebranding/atoms/Icons/Download.component";
import { Button } from "get-life-storybook-ts";
import DownloadCloudIcon from "get-life-storybook-ts/lib/components/Icons/DownloadCloudIcon";

export const DownloadItem = ({ docId, title, total }) => {
  const { token, setShowNotification } = useContext(ContextGetlife);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const pdfElement = useRef();
  const listRepository = new HttpListRepository(token);
  const [buttonLoading, setButtonLoading] = useState(false)
  const [linkPDF, setLinkPDF] = useState()

  const link = {
    linkRef: pdfElement,
    linkUrl: "",
  };

  useEffect(()=> {
    if(linkPDF){
      pdfElement.current?.setAttribute(
        "download",
        `data:application/pdf;base64,${linkPDF}`
      );
      pdfElement.current?.setAttribute(
        "href",
        `data:application/pdf;base64,${linkPDF}`
      );
      pdfElement.current?.click();
    }

  }, [linkPDF])

  const handleDoc = async () => {
    setButtonLoading(true)
    try {
      const response = await listRepository.getDocumentInvoicesDetail(docId);
      setLinkPDF(response.content)
    } catch (error) {
      setShowNotification({
        message: translate(`errors.noPDF`),
        status: true,
        type: "error",
      });
    }
    setButtonLoading(false)
  };

  return (
    <div className="download-item__wrapper">
      <div className="download-item__title">
        <span>{title}</span>
      </div>
      <div className="download-item__content">
        <div className="download-item__total">
          <span> {`${constants.total_text} ${total}`}</span>
        </div>
        <div className="download-item__download">
          <Button
            icon={<DownloadCloudIcon />}
            label={title}
            loading={buttonLoading}
            buttonType='text'
            fitContent
            iconPosition="right"
            onClick={() => handleDoc()}
            link={link}
          />
        </div>
      </div>
    </div>
  );
};
