import {
  genderOptions,
  getValidateEmail,
  intentionPurchase,
  typeInsurance,
} from "../../../utils/staticData";
import Grid from "@mui/material/Grid";

import SelectGeneral from "../SelectGeneral/SelectGeneral";
import InputGeneral from "../../Rebranding/molecules/InputGeneral/InputGeneral.component";
import { errorMessage } from "../../../utils/error";
import InfoMessage from "../../Rebranding/atoms/InfoMessage/InfoMessage.component";
import AlertIcon from "../../Rebranding/atoms/Icons/AlertIcon.component";
import Typography from "../../Rebranding/atoms/Typography/Typography.component";
import "./formCalcPrice.styles.scss";
import numberFormat from "../../../utils/numberFormat";
import Button from "../../Rebranding/atoms/Buttons/ButtonBase/Button.component";
import NumberBox from "../../Rebranding/molecules/NumberBox/NumberBox.component";

export default function FormCalcPrice(props) {
  const {
    buttonCalculate,
    capitalLimits,
    death,
    disability,
    error,
    handleSubmitOrganic,
    lessCapital,
    moreCapital,
    optionsDays,
    optionsMonths,
    optionsYears,
    setCapital,
    setInsuranceType,
    setValue,
    showAlerts,
    translate,
    values,
  } = props;

  const textCapital = `${numberFormat(
    capitalLimits.minCapital,
    "capital"
  )} - ${numberFormat(capitalLimits.maxCapital, "capital")}`;

  const isDisability = disability !== undefined && !disability;

  let form = values;
  if (isDisability) {
    form = {
      ...form,
      insuranceType: { value: "basic", label: translate("info.death") },
    };
  }

  return (
    <div className="dashboard-rightFormContainer">
      <Grid container className="dashboard-rightGridContainer">
        <div className="dashboard-birthDateContainerMain">
          <p className={"selectGetLife-label"}>{translate("calculator.label.birthday")}</p>

          <div className="dashboard-birthDateContainer">
            <Grid item xs={12} md={3.5}>
              <SelectGeneral
                editValue={form["day"]}
                error={errorMessage(error, "day")}
                handleValues={(newValue) => setValue("day", newValue)}
                placeholder={translate("calculator.placeholder.day")}
                toMap={optionsDays}
                width={{ width: "41px" }}
              />
            </Grid>

            <Grid item xs={12} md={3.5}>
              <SelectGeneral
                editValue={form["month"]}
                error={errorMessage(error, "month")}
                handleValues={(newValue) => setValue("month", newValue)}
                placeholder={translate("calculator.placeholder.month")}
                toMap={optionsMonths}
                width={{ width: "41px" }}
              />
            </Grid>

            <Grid item xs={12} md={3.5}>
              <SelectGeneral
                editValue={form["year"]}
                error={errorMessage(error, "year")}
                handleValues={(newValue) => setValue("year", newValue)}
                placeholder={translate("calculator.placeholder.year")}
                toMap={optionsYears}
                width={{ width: "41px" }}
              />
            </Grid>
          </div>
        </div>
      </Grid>
      <div className="dashboard-formRows">
        <Grid container className="dashboard-rightGridContainer">
          <div className="dashboard-birthDateContainer">
            <Grid item md={5.8}>
              <InputGeneral
                editValue={form["zipCode"]}
                error={errorMessage(error, "zipCode")}
                id="input-zipCode"
                isNumber
                label={translate("calculator.label.zipcode")}
                name="zipCode"
                placeholder={translate("calculator.placeholder.zipcode")}
                setter={(newValue) => setValue("zipCode", newValue)}
              />
            </Grid>
            <Grid item md={5.8}>
              <SelectGeneral
                editValue={form["gender"]}
                error={errorMessage(error, "gender")}
                handleValues={(newValue) => setValue("gender", newValue)}
                label={translate("calculator.label.gender")}
                placeholder={translate("calculator.placeholder.gender")}
                toMap={genderOptions(translate)}
              />
            </Grid>
          </div>
        </Grid>
      </div>
      <div className="dashboard-formRows">
        <Grid container className="dashboard-rightGridContainer">
          <div className="dashboard-birthDateContainer">
            <Grid item md={12} style={{ marginTop: "-15px" }}>
              <NumberBox
                className={"dashboard-birthDateContainer"}
                value={form["capital"]}
                disabled={!form["capital"]}
                suffix={translate("edit.project.input.numberbox.suffix")}
                label={translate("calculator.label.capital")}
                setterOnBlur={(newValue) => {
                  setCapital(newValue, setValue);
                }}
                add={() => moreCapital(form, setValue)}
                disabledPlus={capitalLimits.maxCapital === form["capital"]}
                disabledMinus={capitalLimits.minCapital === form["capital"]}
                remove={() => lessCapital(form, setValue)}
                name="capital"
                error={death && errorMessage(error, "capital")}
              />
            </Grid>
          </div>
          {showAlerts.maxCapital && (
            <InfoMessage
              className="infoMessage"
              classNameText="infoMessage-text"
              icon={<AlertIcon color="#423EF4" width={30} height={30} />}
              message={
                <>
                  {translate("info.dashboard.capitalLimits")}{" "}
                  <Typography
                    variant="bold"
                    className="infoMessage-emphasis"
                    text={textCapital}
                  />
                </>
              }
              type="info"
            />
          )}
        </Grid>
      </div>
      <div className="dashboard-formRows">
        <Grid container className="dashboard-rightGridContainer">
          <div className="dashboard-birthDateContainer">
            <Grid item md={5.8}>
              <InputGeneral
                editValue={form["phone"]}
                error={errorMessage(error, "phone")}
                id="input-phone"
                isNumber
                label={translate("calculator.label.phone")}
                name="phone"
                placeholder={translate("calculator.placeholder.phone")}
                setter={(newValue) => setValue("phone", newValue)}
              />
            </Grid>
            <Grid item md={5.8}>
              <SelectGeneral
                editValue={form["intention"]}
                error={errorMessage(error, "intention")}
                label={translate("calculator.label.intention")}
                handleValues={(newValue) => setValue("intention", newValue)}
                toMap={intentionPurchase(translate)}
                placeholder={translate("calculator.placeholder.intention")}
              />
            </Grid>
          </div>
        </Grid>
      </div>
      <div className="dashboard-formRows">
        <Grid container className="dashboard-rightGridContainer">
          <div className="dashboard-birthDateContainer">
            <Grid item md={12}>
              <InputGeneral
                editValue={getValidateEmail(form["email"])}
                error={errorMessage(error, "email")}
                id="input-email"
                label={translate("calculator.label.email")}
                name="email"
                contentType="mail"
                placeholder={translate("calculator.placeholder.email")}
                setter={(newValue) => setValue("email", newValue)}
              />
            </Grid>
          </div>
        </Grid>
      </div>
      <div className="dashboard-formRows">
        <Grid container className="dashboard-rightGridContainer">
          <div className="dashboard-birthDateContainer">
            <Grid item md={12}>
              <InputGeneral
                editValue={form["referenceName"]}
                error={errorMessage(error, "referenceName")}
                id="input-referenceName"
                label={translate("calculator.label.referenceName")}
                name="referenceName"
                contentType="referenceName"
                placeholder={translate("calculator.placeholder.referenceName")}
                setter={(newValue) => setValue("referenceName", newValue)}
              />
            </Grid>
          </div>
        </Grid>
      </div>
      <div className="dashboard-formRows">
        <Grid container className="dashboard-rightGridContainer">
          <div className="dashboard-birthDateContainer">
            <Grid item md={12}>
              <SelectGeneral
                label={translate("calculator.label.insuranceType")}
                editValue={form["insuranceType"]}
                error={errorMessage(error, "insuranceType")}
                handleValues={(newValue) => {
                  setValue("insuranceType", newValue);
                  setInsuranceType(newValue);
                }}
                toMap={
                  !isDisability
                    ? typeInsurance(translate)
                    : [{ value: "basic", label: translate("info.death") }]
                }
                placeholder={translate("calculator.placeholder.insuranceType")}
              />
              {showAlerts.disability && (
                <InfoMessage
                  className="infoMessage"
                  classNameText="infoMessage-text"
                  icon={<AlertIcon color="#423EF4" width={30} height={30} />}
                  message={
                    <>
                      {translate("info.dashboard.IPA")}{" "}
                      <Typography
                        variant="bold"
                        className="infoMessage-emphasis"
                        text={translate("info.death")}
                      />
                    </>
                  }
                  type="info"
                />
              )}
            </Grid>
          </div>
        </Grid>
      </div>
      <div className="dashboard-buttonContainer">
        <Button onClick={() => handleSubmitOrganic(form, setValue)}>
          {translate(`dashboard.buttons.${buttonCalculate}`)}
        </Button>
      </div>
      {showAlerts.recalcDisability && (
        <InfoMessage
          className="infoMessage"
          classNameText="infoMessage-text"
          icon={
            <AlertIcon
              style={{ minWidth: "25px" }}
              color="#423EF4"
              width={30}
              height={30}
            />
          }
          message={
            <>
              {translate("info.dashboard.recalculateGuarantee")}{" "}
              <Typography
                variant="bold"
                className="infoMessage-emphasis"
                text={translate("info.death")}
              />
            </>
          }
          type="info"
        />
      )}
      {showAlerts.recalcMaxCapital && (
        <InfoMessage
          className="infoMessage"
          classNameText="infoMessage-text"
          icon={
            <AlertIcon
              style={{ minWidth: "25px" }}
              color="#423EF4"
              width={30}
              height={30}
            />
          }
          message={
            <>
              {translate("info.dashboard.recalculate")}{" "}
              <Typography
                variant="bold"
                className="infoMessage-emphasis"
                text={textCapital}
              />
            </>
          }
          type="info"
        />
      )}
    </div>
  );
}
