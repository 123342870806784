import * as React from "react";
import { useNavigate } from "react-router";
import HttpLeadRepository from "../../../../api/request/Lead/lead.service";
import {
  AddressData,
  NationalityData,
  OtherBeneficiariesTypes,
  PersonalData,
} from "../../../../api/request/Lead/Model/Request/PostStackRequest.model";
import { Answers } from "../../../../api/request/Lead/Model/Response/NomenclatorResponse.model";
import PostStackResponse from "../../../../api/request/Lead/Model/Response/PostStackResponse.model";
import { ContextGetlife } from "../../../../contexts/ContextGetlife";
import { I18nContext } from "../../../../contexts/i18n.context";
import { getValidateEmail } from "../../../../utils/staticData";
import {
  BankEntityValues,
  getSummation,
  LegalPersonValues,
  PhysicalPersonValues,
} from "../../organisms/Forms/FormOtherBeneficiaries/FormOtherBeneficiaries.controller";

const answerEmpty = {
  value: "",
  text: "",
  label: "",
  blocker: false,
  blockerText: "",
};

const initialValuesPersonalData = {
  name: "",
  lastName: "",
  lastName2: "",
  email: "",
  phone: "",
};

const initialValuesNationality = {
  nationality: answerEmpty,
  nationality2: answerEmpty,
  citizenship: answerEmpty,
  idNumber: "",
};

const initialValuesAddress = {
  addressLine: "",
  zipcode: "0",
  province: answerEmpty || "",
  city: "",
};

export interface ActiveForms {
  address: boolean;
  beneficiaries: boolean;
  nationality: boolean;
  personalData: boolean;
}

export interface Beneficiaries {
  id: "legalHeirs" | "prelationOrder" | "other";
  label: string;
  name: "legalHeirs" | "prelationOrder" | "other";
  value: string;
}

interface BeneficiariesValues {
  beneficiaries: Beneficiaries;
  capital: number;
  beneficiariesList: OtherBeneficiariesValues[];
}

interface NationalityValues
  extends Omit<
    NationalityData,
    "citizenship" | "nationality" | "nationality2"
  > {
  citizenship?: Answers;
  nationality?: Answers;
  nationality2?: Answers;
}

export interface AddressValues
  extends Omit<AddressData, "province" | "zipcode"> {
  zipcode: string;
  province?: Answers;
}

interface InitialValues {
  address: AddressValues;
  nationality: NationalityValues;
  personalData: PersonalData;
  beneficiaries: BeneficiariesValues;
}

type OtherBeneficiariesValues =
  | LegalPersonValues
  | BankEntityValues
  | PhysicalPersonValues;

export type QuestionTypes =
  | "address"
  | "beneficiaries"
  | "nationality"
  | "personalData";

const initialActiveForm: ActiveForms = {
  beneficiaries: false,
  address: false,
  nationality: false,
  personalData: true,
};

const initialAnswered: ActiveForms = {
  ...initialActiveForm,
  personalData: false,
};

const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

const initialBeneficiaries = (
  translate: (key: string) => string
): Beneficiaries[] => {
  const beneficiaries: Beneficiaries[] =
  DOMAIN !== "fr"
      ? [
        {
          label: translate("questions.beneficiaries.legalHeirs"),
          value: "Herederos Legales",
          name: "legalHeirs",
          id: "legalHeirs",
        },
        {
          label: translate("questions.beneficiaries.orderOfPrecedence"),
          value: "Orden de Prelación",
          name: "prelationOrder",
          id: "prelationOrder",
        },
        {
          label: translate("questions.beneficiaries.otherBeneficiaries"),
          value: "other",
          name: "other",
          id: "other",
        },
      ]
      : [
        {
          label: translate("questions.beneficiaries.legalHeirs"),
          value: "Herederos Legales",
          name: "legalHeirs",
          id: "legalHeirs",
        },
        {
          label: translate("questions.beneficiaries.otherBeneficiaries"),
          value: "other",
          name: "other",
          id: "other",
        },
      ];
  return beneficiaries;
};

const PostStackController = () => {
  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const { leadId, setEmail, setLoading, token, setShowNotification } =
    React.useContext(ContextGetlife);

  const navigate = useNavigate();

  const leadRepository = new HttpLeadRepository(token);

  const [initialValues, setInitialValues] = React.useState<InitialValues>();

  const [activeForm, setActiveForm] =
    React.useState<ActiveForms>(initialActiveForm);

  const [nationalities, setNationalities] = React.useState<Answers[]>();

  const [provincies, setProvincies] = React.useState<Answers[]>();

  const [beneficiaries, setBeneficiaries] = React.useState<Beneficiaries[]>(
    initialBeneficiaries(translate)
  );

  const [isAnswered, setIsAnswered] =
    React.useState<ActiveForms>(initialAnswered);

  const [emailDisabled, setEmailDisabled] = React.useState<boolean>(false);

  const [popUpCalendly, setPopUpCalendly] = React.useState<boolean>(false);

  const areInitialValues = (initialData: any): boolean => {
    if (!initialData) {
      return false;
    }
    return Object.values(initialData).every((value) => {
      if (
        "nationality2" in initialData &&
        !initialData["nationality2"] &&
        initialData["citizenship"]
      ) {
        return true;
      }
      return !!value;
    });
  };

  const getMessage = (error: any) => {
    if (!error.code) {
      return translate("errors.postStack.insuficientCapital");
    } else if (!error.invalidTypes.length) {
      return translate("errors.postStack.beneficiariesTypes");
    }
    return "";
  };

  const getLocation = (value: string, localities: Answers[]): Answers => {
    return localities.filter((locality) => locality.value === value)[0];
  };

  const getActiveForms = (
    personalData: PersonalData,
    nationalityData: NationalityValues,
    addressData: AddressValues,
    beneficiariesData: BeneficiariesValues
  ) => {
    if (
      areInitialValues(personalData) &&
      areInitialValues(nationalityData) &&
      areInitialValues(addressData) &&
      !!beneficiariesData
    ) {
      setActiveForm({
        beneficiaries: true,
        address: true,
        nationality: true,
        personalData: true,
      });
      setIsAnswered({
        beneficiaries: true,
        address: true,
        nationality: true,
        personalData: true,
      });
    } else if (
      areInitialValues(personalData) &&
      areInitialValues(nationalityData) &&
      areInitialValues(addressData)
    ) {
      setActiveForm({
        ...activeForm,
        nationality: true,
        address: true,
        beneficiaries: true,
      });
      setIsAnswered({
        ...isAnswered,
        personalData: true,
        nationality: true,
        address: true,
      });
    } else if (
      areInitialValues(personalData) &&
      areInitialValues(nationalityData)
    ) {
      setActiveForm({
        ...activeForm,
        nationality: true,
        address: true,
      });
      setIsAnswered({
        ...isAnswered,
        nationality: true,
        personalData: true,
      });
    } else if (areInitialValues(personalData)) {
      setActiveForm({ ...activeForm, nationality: true });
      setIsAnswered({
        ...isAnswered,
        personalData: true,
      });
    }
  };

  const validatePhone = (phone: string) => {
    if (!phone) return phone;

    const lang = process.env.REACT_APP_DOMAIN_LOCALE!;
    const prefix: { [key: string]: string } = {
      es: "0034",
      fr: "0033"
    }
    const incorrectPhone = phone.startsWith(prefix[lang])

    if (incorrectPhone) {
      const fixedPhone = phone.replace(prefix[lang], '');
      if(lang === "fr") return `0${fixedPhone}`
      return fixedPhone;
    }
    return phone;
  };

  const getBeneficiariesList = (
    beneficiariesList: any
  ): OtherBeneficiariesValues[] => {
    const result: OtherBeneficiariesValues[] = [];
    beneficiariesList.forEach((beneficiary: any) => {
      if (beneficiary.type === "physicalPerson") {
        const checked = beneficiary.idNumber === "N/A";
        result.push({ ...(beneficiary as PhysicalPersonValues), checked });
      } else if (beneficiary.type === "legalPerson") {
        result.push({ ...(beneficiary as LegalPersonValues) });
      } else {
        result.push({ ...(beneficiary as BankEntityValues) });
      }
    });

    return result;
  };

  const fetchLocalion = async () => {
    try {
      let country = DOMAIN !== "fr" ? "country" : "country_fr"
      const countries: Answers[] = await (
        await leadRepository.locationByCategory(country)
      ).answers;

      const province =
     DOMAIN !== "fr"
          ? "province_spain"
          : "province_france";

      const provinciesData: Answers[] = await (
        await leadRepository.locationByCategory(province)
      ).answers;
      setNationalities([...countries]);
      setProvincies([...provinciesData]);
    } catch (error) {
      setNationalities([]);
      setProvincies([]);
    }
  };

  const fetchInitialData = async () => {
    try {
      const data: PostStackResponse = await leadRepository.getPostStackData(
        leadId
      );

      const { lead } = data;

      const correctEmail = getValidateEmail(lead.email);

      const personalData: PersonalData = {
        name: lead.name,
        email: correctEmail,
        phone: validatePhone(lead.phone),
        lastName: lead.lastName,
        lastName2: lead.lastName2,
      };

      const nationalityUser =
        process.env.REACT_APP_DOMAIN_LOCALE === "fr"
          ? lead.nationality
            ? lead.nationality
            : "FR"
          : lead.nationality;

      const citizenshipUser =
        process.env.REACT_APP_DOMAIN_LOCALE === "fr"
          ? lead.citizenship
            ? lead.citizenship
            : "FR"
          : lead.citizenship;
      const nationalityData: NationalityValues = {
        nationality: getLocation(nationalityUser, nationalities || []),
        nationality2: getLocation(lead.nationality2, nationalities || []),
        citizenship: getLocation(citizenshipUser, nationalities || []),
        idNumber: lead.idNumber,
      };

      const addressData: AddressValues = {
        addressLine: lead.addressLine,
        zipcode: lead.zipcode.toString(),
        province: getLocation(lead.province, provincies || []) || lead.province,
        city: lead.city,
      };

      let beneficiariesData: BeneficiariesValues = {
        beneficiaries: !!lead.beneficiariesList.length
          ? beneficiaries.filter((item) => item.id === "other")[0]
          : beneficiaries.filter(
            (beneficiary) => beneficiary.value === lead.beneficiaries
          )[0],
        beneficiariesList: getBeneficiariesList(lead.beneficiariesList),
        capital: lead.capital,
      };

      if (!lead.beneficiariesList.length && !lead.beneficiaries) {
        beneficiariesData = {
          beneficiaries: beneficiaries[0],
          beneficiariesList: [],
          capital: lead.capital,
        };
      }
      const initialData: InitialValues = {
        address: addressData,
        nationality: nationalityData,
        personalData: personalData,
        beneficiaries: beneficiariesData,
      };
      getActiveForms(
        personalData,
        nationalityData,
        addressData,
        beneficiariesData
      );
      setInitialValues(initialData);
      setEmailDisabled(!!correctEmail);
    } catch (error) {
      setInitialValues({
        personalData: initialValuesPersonalData,
        nationality: initialValuesNationality,
        address: initialValuesAddress,
        beneficiaries: {
          beneficiaries: beneficiaries[0],
          beneficiariesList: [],
          capital: 0,
        },
      });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      if (nationalities === undefined && provincies === undefined) {
        await fetchLocalion();
      }
      if (
        initialValues === undefined &&
        nationalities !== undefined &&
        provincies !== undefined
      ) {
        await fetchInitialData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalities, provincies]);

  const handleBeneficiaries = (disabled: boolean): void => {
    const newBeneficiaries = beneficiaries.map((obj) => ({
      ...obj,
      disabled: disabled,
    }));
    setBeneficiaries(newBeneficiaries);
  };

  const handleIsAnswer = (
    type: "address" | "beneficiaries" | "nationality" | "personalData"
  ) => {
    setIsAnswered({ ...isAnswered, [type]: false });
    handleBeneficiaries(false);
  };

  const fetchPersonalData = async (values: PersonalData) => {
    try {
      await leadRepository.postStackData(leadId, values);
      setActiveForm({ ...activeForm, nationality: true });
      setIsAnswered({ ...isAnswered, personalData: true });
      setEmailDisabled(true);
    } catch (error: any) {
      setShowNotification({
        message: translate(`errors.postStack.${error.message}`),
        type: "error",
        status: true,
      });
    }
    setLoading(false);
  };

  const fetchNationality = async (values: NationalityValues) => {
    const newValues: NationalityData = {
      ...values,
      citizenship: values.citizenship?.value,
      nationality: values.nationality?.value,
      nationality2: values.nationality2?.value,
    };

    try {
      await leadRepository.postStackData(leadId, newValues);
      setActiveForm({ ...activeForm, address: true });
      setIsAnswered({ ...isAnswered, nationality: true });
    } catch (error) { }
    setLoading(false);
  };

  const fetchAddress = async (values: AddressValues) => {
    const newValues: AddressData = {
      ...values,
      province:
        values.province && typeof values.province === "string"
          ? values.province
          : values.province?.value,
      zipcode: parseInt(values.zipcode),
    };
    try {
      await leadRepository.postStackData(leadId, newValues);
      setActiveForm({ ...activeForm, beneficiaries: true });
      setIsAnswered({ ...isAnswered, address: true });
    } catch (error) { }
    setLoading(false);
  };

  const fetchBeneficiaries = async (values: BeneficiariesValues) => {
    const newValues = {
      beneficiaries: values.beneficiaries.value,
    };
    const { beneficiariesList } = values;
    beneficiariesList.forEach((item) => {
      if ("checked" in item) {
        delete item.checked;
      }
      return item;
    });
    const beneficiariesSet: Set<OtherBeneficiariesTypes> = new Set();
    beneficiariesList.forEach((beneficiary) =>
      beneficiariesSet.add(beneficiary.type)
    );
    const beneficiariesTypes: OtherBeneficiariesTypes[] =
      Array.from(beneficiariesSet);

    try {
      const capital = values.beneficiariesList.length
        ? values.capital - getSummation(values.beneficiariesList)
        : values.capital;

      if (capital !== 0 && values.beneficiaries.id === "other") {
        throw new Error();
      }
      await leadRepository.postStackData(leadId, newValues);

      if (values.beneficiaries.id !== "other") {
        await leadRepository.deleteBeneficiaries(leadId);
        setIsAnswered({ ...isAnswered, beneficiaries: true });
        handleBeneficiaries(true);
      } else {
        await leadRepository.postBeneficiaries({
          beneficiaries: beneficiariesList,
          leadId,
        });

        await leadRepository.postBeneficiariesType({
          leadId,
          beneficiariesTypes,
        });
        setIsAnswered({ ...isAnswered, beneficiaries: true });
        handleBeneficiaries(true);
      }
    } catch (e: any) {
      const error = {
        code: e.code,
        invalidTypes: beneficiariesTypes,
      };
      setShowNotification({
        message: getMessage(error),
        type: "error",
        status: true,
      });
    }
    setLoading(false);
  };

  const petitions = {
    address: fetchAddress,
    beneficiaries: fetchBeneficiaries,
    nationality: fetchNationality,
    personalData: fetchPersonalData,
  };

  const selectData = {
    address: provincies,
    beneficiaries: beneficiaries,
    nationality: nationalities,
    personalData: [],
  };

  const postStackQuestionTypes: QuestionTypes[] = Object.keys(
    activeForm
  ) as Array<QuestionTypes>;

  return {
    activeForm,
    areInitialValues,
    emailDisabled,
    handleIsAnswer,
    initialValues,
    isAnswered,
    navigate,
    petitions,
    popUpCalendly,
    postStackQuestionTypes,
    selectData,
    setEmail,
    setPopUpCalendly,
    translate,
  };
};

export default PostStackController;
