// Components
import { CustomTable } from "../components/CustomTable/custom-table.component";
import Button from "../Rebranding/atoms/Buttons/ButtonBase/Button.component";
// Controller
import EmployeesController from "./controllers/Employees.controller";
import EditEmployee from "./EditEmployee.component";
import "./Employees.scss";
import InvitationEmployee from "./InvitationModal.component";

const Employees = () => {
  const {
    error,
    handleSendInvitation,
    handleSubmit,
    setShowInvitation,
    setShowModal,
    setValue,
    showInvitation,
    showModal,
    tableProps,
    translate,
    values,
  } = EmployeesController();

  return (
    <div id="employee-page" className="employees__container">
      <CustomTable
        {...tableProps}
        componentButton={
          <Button
            onClick={() => setShowInvitation(true)}
            className="employees-invitation-button"
          >
            {translate("employees.button.invite")}
          </Button>
        }
      />

      <InvitationEmployee
        showInvitation={showInvitation}
        id="email-invitation"
        setShowInvitation={setShowInvitation}
        handleSubmit={handleSendInvitation}
        translate={translate}
      />

      <EditEmployee
        id="employee-page"
        values={values}
        error={error}
        showModal={showModal}
        setValue={setValue}
        setShowModal={setShowModal}
        handleSubmit={handleSubmit}
        translate={translate}
      />
    </div>
  );
};

export default Employees;
