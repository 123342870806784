import React from 'react';
import { useNavigate } from "react-router-dom";

import Button from '../../atoms/Buttons/ButtonBase/Button.component';

import './NoDataModal.styles.scss';

interface NoDataModalProps {
    title: string;
    buttonText: string;
}

const NoDataModal: React.FC<NoDataModalProps> = ({title, buttonText}) => {

    const navigate = useNavigate();
    return (
        <div className='noData'>
            <h3 className='noData--title'>{title}</h3>
            <Button buttonType='primary' children={buttonText} disabled={false} onClick={() => navigate("/dashboard/calculator")}/>
        </div>
    
    )
}

export default NoDataModal; 