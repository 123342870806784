import { useContext, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router";
import * as FullStory from '@fullstory/browser';
import CustomRadio from "../../components/CustomRadio/CustomRadio";
import { formatPriceTwoDecimals, paymentRadius } from "../../../utils/staticData";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import ModalSendOtp from "./ModalSendOtp/ModalSendOtp";
import apiSendOtpPost from "../../../api/request/apiSendOtpPost";
import apiPaymentMethod from "../../../api/request/apiPaymentMethod";
import apiPaymentFinish from "../../../api/request/apiPaymentFinish";
import HttpTrackingRepository from "../../../api/request/Tracking/tracking.service";
import { GoogleContext } from "../../../contexts/GoogleTagManager.context";
import { GUARANTEE } from "../../../utils/staticData";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { getTrackingUtms } from "../../../api/request/apiLeadTrackingGet";

const CardPayment = (props) => {
  const {
    clientSecret,
    setupIntent,
    leadId,
    token,
    paymentMethod,
    handlePaymentMethod,
    brokerId,
    productCard,
    translate,
    broker
  } = props;

  const [acceptConditions, setAcceptConditions] = useState("");
  const [textButton, setTextButton] = useState(translate("payment.button.sendLink"));
  const textButtonCard = translate("payment.button.pay");
  const [showModal, setShowModal] = useState(false);
  const [receiver, setReceiver] = useState(setupIntent.name);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { handleChangeDataLayer } = useContext(GoogleContext);

  const navigate = useNavigate();

  const leadRepository = new HttpLeadRepository(token);
  const trackingRepository = new HttpTrackingRepository(token);

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const handleSubmit = async () => {
    let eventDetails = [
      {
        leadId: leadId,
        paymentMethod: "card",
        token: token,
        brokerEmployeeId: brokerId,
      },
    ];

    trackingRepository.add({ eventType: "PAYMENT_TRY", eventDetails });

    if (!stripe || !elements) {
      eventDetails = [
        {
          leadId: leadId,
          paymentMethod: "card",
          errorMessage: "INTENT_NOT_LOADED",
          token: token,
          brokerEmployeeId: brokerId,
        },
      ];
      trackingRepository.add({ eventType: "PAYMENT_ERROR", eventDetails });
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: setupIntent.name + " " + setupIntent.lastName,
        },
      },
    });
    if (result.error) {
      eventDetails = [
        {
          leadId: leadId,
          paymentMethod: "card",
          errorMessage: result.error,
          token: token,
          brokerEmployeeId: brokerId,
        },
      ];
      trackingRepository.add({ eventType: "PAYMENT_ERROR", eventDetails });
      setLoading(false);
    } else {
      const paymentMethodData = {
        payment_method: result.setupIntent.payment_method,
        setupIntent: result.setupIntent.id,
        leadId: leadId,
        type: "card",
      };
      await apiPaymentMethod(token, paymentMethodData).then(async (res) => {
        if (res === "OK") {
          await apiPaymentFinish(token, leadId).then(async (status) => {
            if (
              status.status === "succeeded" ||
              status.status === "processing"
            ) {
              eventDetails = [
                {
                  leadId: leadId,
                  token: token,
                  brokerEmployeeId: brokerId,
                },
              ];
              trackingRepository.add({
                eventType: "PAYMENT_SUCCESS",
                eventDetails,
              });

              await leadRepository.getPostStackData(leadId).then((async (leadResponse) => {
                const utms = await getTrackingUtms(leadId);
                const eventData = {
                  page: window.location.href,
                  leadId: leadId,
                  calculatedCapital: parseInt(productCard.capital),
                  coverage: GUARANTEE[productCard.insuranceType],
                  calculatedPremium: parseFloat(productCard.monthlyPrice),
                  intent: productCard.intention,
                  paymentMethod: paymentMethodData.type,
                  brokerEmployeeId: brokerId,
                  brokerId: broker.brokerageId,
                  platform: "Broker",
                  discountedPremium: productCard.promotion ? parseFloat(formatPriceTwoDecimals(productCard.promotion.price)) : parseFloat(formatPriceTwoDecimals(productCard.monthlyPrice)),
                  paymentPeriod: productCard.period ? productCard.period : "monthly",
                  policyId: leadId,
                  entryPage: null,
                  referrerUrl: null,
                  ...utms
                }

                handleChangeDataLayer({
                  event: 'paymentMethodSet',
                  birthDate: leadResponse.lead.birthdate,
                  ...eventData
                });

                handleChangeDataLayer({
                  event: 'policyPurchased',
                  birthDate: leadResponse.lead.birthdate,
                  ...eventData
                });

                FullStory.event('paymentMethodSet', {
                  birthDate: new Date(leadResponse.lead.birthdate),
                  ...eventData
                });

                FullStory.event('policyPurchased', {
                  birthDate: new Date(leadResponse.lead.birthdate),
                  ...eventData
                });
              }))


              navigate("/waitingsignature");
            } else {
              window.location.href = status.secureURL;
            }
          });
        } else {
          eventDetails = [
            {
              leadId: leadId,
              paymentMethod: "card",
              errorMessage: "SAVE_PAYMENT_METHOD" + res,
              token: token,
              brokerEmployeeId: brokerId,
            },
          ];
          trackingRepository.add({ eventType: "PAYMENT_ERROR", eventDetails });
        }
      });
    }
  };

  const sendLinkOtp = async () => {
    setShowModal(true);
    if (paymentMethod === "sms") {
      setReceiver(setupIntent.phone);
    } else {
      setReceiver(setupIntent.email);
    }
  };

  const sendEmail = async () => {
    let channel = paymentMethod;
    await apiSendOtpPost(token, leadId, channel, receiver).then(async (response) => {
      if (response === "ok") {
        setTextButton(translate("payment.button.linkSent"));
        setShowModal(false);

        await leadRepository.getPostStackData(leadId).then(async (leadResponse) => {
          const event = "paymentMethodOTPrequest";
          const utms = await getTrackingUtms(leadId);
          const eventData = {
            leadId: leadId,
            calculatedCapital: productCard.capital,
            coverage: GUARANTEE[productCard.insuranceType],
            calculatedPremium: productCard.monthlyPrice,
            intent: productCard.intention,
            page: window.location.href,
            brokerEmployeeId: brokerId,
            brokerId: broker.brokerageId,
            email: leadResponse.lead.email,
            phone: leadResponse.lead.phone ?? "",
            postalCode: leadResponse.lead.zipcode,
            gender: leadResponse.lead.gender,
            platform: "Broker",
            entryPage: null,
            referrerUrl: null,
            ...utms
          }

          handleChangeDataLayer({
            event,
            birthDate: leadResponse.lead.birthdate,
            ...eventData
          });
          FullStory.event(event, {
            birthDate: new Date(leadResponse.lead.birthdate),
            ...eventData
          });
        });
      }
    });
  };

  const link = (DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it") ? "https://storage.googleapis.com/getlife-bucket-1/public/pdf/note2.pdf" : "https://storage.googleapis.com/getlife-bucket-1/public/pdf/note5.pdf"

  const textAcceptTerms = () => {
    return (
      <span>
        {translate("payment.check.legalnote1")}
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          <b>{translate("payment.check.legalnote2")}</b>
        </a>
        {translate("payment.check.legalnote3")}

      </span>
    );
  };

  return (
    <div className="card-select-payment-type">
      <div className="subcontainer">
        <h5>{translate("payment.paymentMethod.cardTitle")}</h5>
        {paymentMethod !== "now" ? (
          <p>
            {translate("payment.paymentMethod.cardSubTitle")}
          </p>
        ) : (
          <p>
            {translate("payment.paymentMethod.cardSubTitleNow")}
          </p>
        )}

        <CustomRadio
          answer={paymentMethod}
          toMap={paymentRadius(translate)}
          handleValue={handlePaymentMethod}
          vertical={true}
          disabled={
            textButtonCard === translate("payment.button.payDone") ||
              textButton === translate("payment.button.linkSent")
              ? true
              : false
          }
        />
        {paymentMethod === "now" && (
          <div className="container-card">
            <CardElement />
            <p>
              {translate("payment.paymentMethod.now3DS")}
            </p>
          </div>
        )}
      </div>
      <div className="button-container-payment">
        {paymentMethod === "now" && (
          <>
            <div className="container-legal-text">
              <CustomRadio
                answer={acceptConditions}
                toMap={[
                  {
                    value: "true",
                    text: textAcceptTerms(),
                  },
                ]}
                handleValue={() => setAcceptConditions("true")}
                vertical={true}
              />
            </div>
            <ButtonPrimary
              content={textButtonCard}
              onClick={handleSubmit}
              disabled={acceptConditions !== "true" || loading ? true : false}
              loading={loading}
            />
          </>
        )}
        {(paymentMethod === "email" || paymentMethod === "sms") && (
          <div>
            <ButtonPrimary
              content={textButton}
              disabled={textButton === translate("payment.button.linkSent") ? true : false}
              onClick={sendLinkOtp}
            />
            {textButton === translate("payment.button.linkSent") && (
              <button className="re-send-otp" onClick={sendLinkOtp}>
                {translate("payment.link.question")} <span>{translate("payment.button.reSend")}</span>
              </button>
            )}
          </div>
        )}
      </div>
      <ModalSendOtp
        showModal={showModal}
        setShowModal={setShowModal}
        sendEmail={() => sendEmail()}
        paymentMethod={paymentMethod}
        setReceiver={setReceiver}
        receiver={receiver}
        translate={translate}
      />
    </div>
  );
};

export default CardPayment;
