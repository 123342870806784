import { errorMessage } from "../../utils/error";
import Form from "../components/Form/Form";
import Button from "../Rebranding/atoms/Buttons/ButtonBase/Button.component";
import RadioGroup from "../Rebranding/atoms/RadioGroup/RadioGroup.component";
import Typography from "../Rebranding/atoms/Typography/Typography.component";
import Card from "../Rebranding/molecules/Card/Card.component";
import InputGeneral from "../Rebranding/molecules/InputGeneral/InputGeneral.component";
import NumberBox from "../Rebranding/molecules/NumberBox/NumberBox.component";
import Modal from "../Rebranding/organisms/Modal/Modal.component";
import EditProjectsController from "./controllers/EditProject.controller";
import "./EditProjects.scss";
import AlertIcon from "../Rebranding/atoms/Icons/AlertIcon.component";
import { InsuranceType } from "../../api/request/Lead/Model/Request/CarrierPricesRequest.model";

interface ModalProps {
  ModalBody: JSX.Element | string;
  ModalTitle?: JSX.Element | string;
  capital: number;
  className?: string;
  classNameContent?: string;
  classNameHeader?: string;
  closeIcon?: boolean;
  disabledButton: (project: any) => boolean;
  data: any; // FIXME
  error: any; // FIXME
  handleCapital: (value: string) => void;
  id: string;
  idModal: string;
  modalData: any; // FIXME
  recoverProject: (leadId: string, data: any) => void;
  setData: (data: any) => void; // FIXME
  setShowModal: (value: boolean) => void;
  showModal: boolean | undefined;
  token: string;
}

const EditProjects: React.FC<ModalProps> = ({
  className,
  classNameContent,
  classNameHeader,
  data,
  disabledButton,
  error,
  id,
  modalData,
  recoverProject,
  setData,
  setShowModal,
  showModal,
  token,
}): JSX.Element => {
  const {
    IPAConfig,
    addCapital,
    capital,
    chosenIPA,
    downloadPdf,
    fetchUpdate,
    formatBirthDate,
    handleCapital,
    handleIPA,
    limits,
    pdfElementDownload,
    prices,
    removeCapital,
    sanitizeCapital,
    traits,
    translate,
  } = EditProjectsController({
    data,
    modalData,
    setData,
    setShowModal,
    token,
  });

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;
  return (
    <Modal
      className={className}
      classNameContent={classNameContent}
      classNameHeader={classNameHeader}
      closeIcon={true}
      setShowModal={setShowModal}
      showModal={showModal}
      ModalTitle={
        <Typography variant="h1" text={translate("edit.project.title")} />
      }
      ModalBody={
        <>
          <Form initialValues={modalData}>
            {({
              values,
              setValue,
            }: {
              values: any;
              setValue: (name: string, value: any) => void;
            }) => (
              <>
                <div className="edit-modal__form">
                  <div className="edit-modal__form__row">
                    <div className="edit-modal__form__item">
                      <InputGeneral
                        editValue={values["name"]}
                        error={errorMessage(error, "name")}
                        id="input-name"
                        label={translate("edit.project.input.name.label")}
                        name="name"
                        setter={(newValue) => setValue("name", newValue)}
                        placeholder={translate(
                          "edit.project.input.name.placeholder"
                        )}
                      />
                    </div>
                    <div className="edit-modal__form__item">
                      <InputGeneral
                        editValue={values["lastName"]}
                        error={errorMessage(error, "lastName")}
                        id="input-lastName"
                        label={translate("edit.project.input.lastname1.label")}
                        name="lastName"
                        setter={(newValue) => setValue("lastName", newValue)}
                        placeholder={translate(
                          "edit.project.input.lastname1.placeholder"
                        )}
                      />
                    </div>
                  </div>
                  <div className="edit-modal__form__row">
                    <div className="edit-modal__form__item">
                      <InputGeneral
                        editValue={values["lastName2"]}
                        error={errorMessage(error, "lastName2")}
                        id="input-lastName2"
                        label={translate("edit.project.input.lastname2.label")}
                        name="lastName2"
                        setter={(newValue) => setValue("lastName2", newValue)}
                        placeholder={translate(
                          "edit.project.input.lastname2.placeholder"
                        )}
                      />
                    </div>
                    <div className="edit-modal__form__item">
                      <InputGeneral
                        disabled={true}
                        editValue={formatBirthDate(values["birthdate"])}
                        error={errorMessage(error, "birthdate")}
                        id="input-birthdate"
                        label={translate("edit.project.input.birthdate.label")}
                        name="birthdate"
                        placeholder={translate(
                          "edit.project.input.birthdate.placeholder"
                        )}
                        setter={(newValue) => setValue("birthdate", newValue)}
                      />
                    </div>
                  </div>

                  <div className="edit-modal__form__row">
                    <div className="edit-modal__form__item">
                      <InputGeneral
                        editValue={values["phone"]}
                        error={errorMessage(error, "phone")}
                        id="input-phone"
                        label={translate("edit.project.input.phone.label")}
                        name="phone"
                        setter={(newValue) => setValue("phone", newValue)}
                        placeholder={translate(
                          "edit.project.input.phone.placeholder"
                        )}
                      />
                    </div>
                    <div className="edit-modal__form__item">
                      <InputGeneral
                        editValue={values["email"]}
                        error={errorMessage(error, "email")}
                        id="input-email"
                        label={translate("edit.project.input.email.label")}
                        name="email"
                        setter={(newValue) => setValue("email", newValue)}
                        placeholder={translate(
                          "edit.project.input.email.placeholder"
                        )}
                      />
                    </div>
                  </div>
                  {!traits?.DEATH && !traits?.DISABILITY && (
                    <div className="edit-modal__death">
                      <AlertIcon />
                      <Typography
                        className="edit-modal__death--text"
                        text={translate("info.edit.death.text")}
                        variant="paragraph"
                      />
                    </div>
                  )}
                  <div className="edit-modal__form__submit">
                    <Button
                      onClick={() => {
                        fetchUpdate(values);
                      }}
                      disabled={!traits?.DEATH && !traits?.DISABILITY}
                    >
                      {translate("edit.project.button.submit")}
                    </Button>
                  </div>
                </div>
                <div className="edit-modal__actions">
                  <div className="edit-modal__text">
                    <Typography
                      variant="paragraph"
                      text={translate("edit.project.info.changes")}
                    />
                  </div>
                  {limits?.maxCapital === 50000 && (
                    <div className="edit-modal__limits">
                      <AlertIcon />
                      <div className="edit-modal__limits--info">
                        <Typography
                          className="capitalLimits--text"
                          text={translate("info.edit.capitalLimits.atention")}
                          variant="paragraph"
                        />
                        <div className="edit-modal__limits--text">
                          <Typography
                            variant="paragraph"
                            text={translate("info.edit.capitalLimits.text")}
                            className="capitalLimits--text"
                          />
                          <Typography
                            variant="paragraph"
                            text={translate("info.edit.capitalLimits.capital")}
                            className="capitalLimits--capital"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {traits?.DEATH === false && !traits?.DISABILITY && (
                    <div className="edit-modal__death">
                      <AlertIcon />
                      <Typography
                        className="edit-modal__death--text"
                        text={translate("info.edit.death.text")}
                        variant="paragraph"
                      />
                    </div>
                  )}
                  <div className="edit-modal__capital__row">
                    <div className="edit-modal__left__column">
                      <NumberBox
                        className={"edit-modal__capital__box"}
                        suffix={translate(
                          "edit.project.input.numberbox.suffix"
                        )}
                        value={capital}
                        setter={(newValue) => {
                          handleCapital(sanitizeCapital(newValue));
                          setValue("capital", sanitizeCapital(newValue));
                        }}
                        add={() => addCapital(setValue)}
                        disabledPlus={limits.maxCapital === values["capital"]}
                        disabledMinus={limits.minCapital === values["capital"]}
                        remove={() => removeCapital(setValue)}
                      />

                      {
                        (DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it") && (
                          <RadioGroup
                            name="edit-project"
                            className="edit-project__radio-group"
                            align="vertical"
                            items={
                              traits?.DISABILITY || traits?.DISABILITY === undefined
                                ? IPAConfig.items
                                : [
                                  {
                                    label: translate(
                                      "edit.project.radio.basic.label"
                                    ),
                                    value: "basic",
                                  },
                                ]
                            }
                            // items={IPAConfig.items}
                            value={chosenIPA}
                            setter={(newValue) => {
                              handleIPA(newValue as InsuranceType);
                              setValue("insuranceType", newValue);
                            }}
                          />
                        )
                      }

                    </div>
                    <div className="edit-modal__right__column">
                      <Card
                        classNameContainer="edit-modal__card"
                        title={translate("edit.project.card.price")}
                        variantTitle="paragraph"
                      >
                        <div className="edit-modal__monthly">
                          {prices.monthlyPrice}
                          {translate("edit.project.card.suffix")}
                          <span>
                            {translate("edit.project.card.monthly.text")}
                          </span>
                        </div>
                        <div className="edit-modal__yearly">
                          {prices.annualPrice}
                          {translate("edit.project.card.suffix")}
                          <span>
                            {translate("edit.project.card.yearly.text")}
                          </span>
                        </div>
                      </Card>
                    </div>
                  </div>
                  {!traits?.DISABILITY && (
                    <div className="edit-modal__disability">
                      <AlertIcon />
                      <div className="edit-modal__limits--info">
                        <Typography
                          className="capitalLimits--text"
                          text={translate("info.edit.capitalLimits.atention")}
                          variant="paragraph"
                        />
                        <div className="edit-modal__limits--text">
                          <Typography
                            variant="paragraph"
                            text={translate("info.edit.disability.text")}
                            className="capitalLimits--text"
                          />
                          <Typography
                            variant="paragraph"
                            text={translate("info.edit.disability.type")}
                            className="capitalLimits--capital"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {!traits?.DEATH && !traits?.DISABILITY && (
                    <div className="edit-modal__death">
                      <AlertIcon />
                      <Typography
                        className="edit-modal__death--text"
                        text={translate("info.edit.death.text")}
                        variant="paragraph"
                      />
                    </div>
                  )}
                </div>

                <div className="edit-modal__form__recover">
                  <Typography
                    className="edit-modal-download-document"
                    text={translate("info.edit.download.document")}
                    variant="paragraph"
                    onClick={() => downloadPdf(values)}
                  />
                  <a
                    ref={pdfElementDownload}
                    href=""
                    target="_blank"
                    id="downloadPDF"
                    style={{ display: "none" }}
                    rel="noreferrer"
                  >
                    ''
                  </a>
                </div>

                <div className="edit-modal__form__recover">
                  <Button
                    onClick={() => recoverProject(values.leadId, values)}
                    buttonType="secondary"
                    className="edit-modal__from__recover__button"
                    disabled={
                      disabledButton(modalData) ||
                      capital === 0 ||
                      capital > limits.maxCapital ||
                      capital < limits.minCapital
                    }
                  >
                    {translate("edit.project.button.recover")}
                  </Button>
                </div>
              </>
            )}
          </Form>
        </>
      }
    />
  );
};

export default EditProjects;
