import React from 'react';
import AllOk from './../../../assets/img/allOk.svg';
import './allOkBanner.scss';

export default function AllOkBanner(props) {
	const { content } = props;
	return (
		<div className='allOkBanner-mainContainer'>
			<div className='allOkBanner-container'>
				<img src={AllOk} alt='Todo ha ido bien' />
				<p>{content}</p>
			</div>
		</div>
	);
}
