import React from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import './css/buttonSecondary.scss';

function ButtonSecondary(props) {

    return (
        <button
            className={
                props.type === 'download-pdf'
                    ? 'button-secondary-downloadPdf'
                    : 'button-secondary'
            }
            disabled={props.disabled}
            onClick={props.handleChange}
            style={{
                backgroundColor: props.backgroundColor,
                width: props.width ? props.width : '',
                visibility: props.visibility ? props.visibility : null,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                }}
            >
                <p className={'button-secondary-content'}>{props.content}</p>
                {props.type === 'download' ? (
                    <FileDownloadIcon />
                ) : props.type === 'sendConditions' ? (
                    <FileUploadIcon />
                ) : null}
            </div>
        </button>
    );
}

export default ButtonSecondary;
