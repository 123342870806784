import React from "react";
import Popover from "@mui/material/Popover";
import CloseIcon from "@mui/icons-material/Close";
import IosShareIcon from "@mui/icons-material/IosShare";
import { makeStyles } from "@material-ui/core";
import ButtonPrimary from "../../../components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../components/buttons/ButtonSecondary";
import InputGetLife from "../../../components/inputGetLife/InputGetLife";
import "./modalSendOtp.scss";
import { Translate } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  popoverRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(100, 100, 100, 0.80)",
  },
}));

export default function ModalSendOtp(props) {
  const { showModal, setShowModal, sendEmail, paymentMethod, setReceiver, receiver, translate } = props;

  const classes = useStyles();

  return (
    <Popover
      open={showModal}
      onClose={() => setShowModal(false)}
      anchorReference={"none"}
      classes={{
        root: classes.popoverRoot,
      }}
    >
      <div className="modalSendEmail">
        <div className="header">
          <div className="tittle">
            <h2>{translate("modal.payment.send.title")} {paymentMethod}</h2>
            <IosShareIcon className="shareIcon" />
          </div>
          <div className="close-box">
            <CloseIcon
              onClick={() => setShowModal(false)}
              className="closeIcon"
            />
          </div>
        </div>
        <div className="box-info-send-otp">
          {paymentMethod === 'email' && (
            <div className="email-box">
              <h5>{translate("modal.payment.send.subtitle1.email")}</h5>
              <p>{translate("modal.payment.send.subtitle2.email")}</p>
            </div>
          )}
          {paymentMethod === 'sms' && (
            <div className="sms-box">
              <h5>{translate("modal.payment.send.subtitle1.phone")}</h5>
              <p>{translate("modal.payment.send.subtitle2.phone")}</p>
            </div>
          )}
          <InputGetLife
            handleValues={(e) => setReceiver(e)}
            type="email"
            answer={receiver}
            label={paymentMethod === 'email' ? translate("modal.payment.label.email") : translate("modal.payment.label.phone")}
          ></InputGetLife>
        </div>
        <div className="button-container-modal-send">
          <ButtonSecondary
            content={translate("modal.sendPdf.button.cancel")}
            className="upsell-personalization-add-button"
            handleChange={() => setShowModal(false)}
          />
          <ButtonPrimary
            content={translate("modal.sendPdf.button.send")}
            className="upsell-personalization-add-button"
            onClick={() => sendEmail()}
            disabled={!receiver}
          />
        </div>
      </div>
    </Popover>
  );
}
