const calcHeightChart = (getLifePrice, bankPrice) => {
  const maxheight= 278 - 58;
  const minheight = maxheight * 0.3;

  const result = (getLifePrice * 100) / bankPrice;

  if (result > 30) {
    let data = maxheight * (result / 100);

    return data;
  }

  return minheight;
}

export default calcHeightChart;
