// Vendors
import { useContext, useState } from "react";
import HttpEmployeeRepository from "../../../api/request/Employee/employee.service";
// Context
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import { I18nContext } from "../../../contexts/i18n.context";
import useForm from "../../../hooks/useForm.hook";
import { fetchData } from "../../../utils/fetchBrokerTables";
import { ROLESTABLE } from "../../../utils/roles";
import { requiredFieldsEmployeeEdit } from "../../../utils/staticData";
import { columns_employees } from "../constants/Employees.constants";

const EmployeesController = () => {
  const [showModal, setShowModal] = useState(false);
  const [showInvitation, setShowInvitation] = useState(false);
  const [data, setData] = useState();
  const [reset, setReset] = useState(false);

  const { setShowNotification, token, setLoading } = useContext(ContextGetlife);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const employeesRepository = new HttpEmployeeRepository(token);

  const getMessage = (message, type) => {
    setShowNotification({
      message: message,
      status: true,
      type: type,
      anchorOrigin: { vertical: "top", horizontal: "center" },
      autoHideDuration: type === "success" ? 3000 : 10000,
    });
  };

  const fetchEmployees = async (params) => {
    await fetchData({ ...params, setData, token, translate });
  };

  const isWarning = (invalidEmails) => {
    const invalid = Object.keys(invalidEmails).map((key) => {
      if (key !== "senEmails") {
        return !!invalidEmails[key].length;
      }
      return false;
    });
    return invalid.filter((element) => !!element)[0] ? "warning" : "success";
  };

  const handleSendInvitation = async (email) => {
    const isList = email.search(",");
    const emails =
      isList === -1
        ? [email.trim()]
        : email.split(",").map((email) => email.trim());
    try {
      const response = await employeesRepository.inviteEmployee(emails);
      setShowInvitation(false);
      setReset(true);
      getMessage(
        translate(`employees.invite.${isWarning(response)}`).replace(
          "$",
          response.sendEmails
        ),
        isWarning(response)
      );
    } catch (e) {
      const newMessage =
        translate(`errors.employees.${e.message}`) || e.message;
      getMessage(newMessage, "error");
    }
  };

  const fetchUpdate = async (values) => {
    const { name, lastname, email, phone, rol, ulid } = values;
    try {
      await employeesRepository.editEmployee({
        name,
        lastname,
        email,
        phone,
        roles: rol.value,
        ulid,
      });
      getMessage(translate(`employees.edit.success`), "success");
      setReset(true);
      setShowModal(false);
    } catch (e) {
      const newMessage =
        translate(`errors.employees.${e.message}`) || e.message;
      getMessage(newMessage, "error");
    }
    setLoading(false);
  };

  const { values, setValues, setValue, error, handleSubmit } = useForm({
    fetch: (values) => fetchUpdate(values),
    setLoading,
    requiredFields: requiredFieldsEmployeeEdit,
  });

  const handlebutton = ({ ref, project }) => {
    const rol = Object.entries(ROLESTABLE).filter(
      (entry) => entry[1] === project.rol || entry[0]
    )[0];
    setShowModal(true);
    setValues({ ...project, rol: { label: translate(rol[1]), value: rol[0] } });
  };

  const tableProps = {
    columns: columns_employees,
    data,
    endDateProp: "2020-01-01",
    fetch: fetchEmployees,
    handlebutton: handlebutton,
    noDataTitle: translate("employees.noData.title"),
    noDataButtonText: translate("employees.noData.buttonText"),
    placeholder: translate("employees.search.placeholder"),
    placeholderDateRange: translate("employees.search.placeholderDateRange"),
    reset,
    textType: translate("employees.header.subtitle"),
    title: translate("employees.header.title"),
    translate: translate,
    type: "employees",
  };

  return {
    error,
    handleSendInvitation,
    handleSubmit,

    setShowInvitation,
    setShowModal,
    setValue,
    showInvitation,
    showModal,
    tableProps,
    translate,
    values,
  };
};

export default EmployeesController;
