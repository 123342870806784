import { useContext, useState } from "react";
import HttpCouponsRepository from "../../../../../api/request/Coupons/Coupons.service";
import { ContextGetlife } from "../../../../../contexts/ContextGetlife";
import useForm from "../../../../../hooks/useForm.hook";
import { CouponFetchI } from "../../../pages/Checkpoint/CheckPoint.controller";

export interface CouponModalControllerI {
  couponCode?: string;
  fetchPromo: (value: CouponFetchI) => void;
  yearlyPrice: number;
}

const CouponModalController = ({
  couponCode,
  fetchPromo,
  yearlyPrice,
}: CouponModalControllerI) => {
  const { leadId, setLoading, token } = useContext(ContextGetlife);
  const [disabled, setDisabled] = useState<boolean>(!!couponCode);

  const couponsRepository = new HttpCouponsRepository(leadId, token);

  const addedCoupon = async (code: string): Promise<void> => {
    try {
      await couponsRepository.applyCoupon({ promotionalCode: code });
    } catch (error) {}
    setLoading(false);
  };

  const { values, setValue, handleSubmit, error } = useForm({
    fetch: (values) =>
      fetchPromo({
        code: values.couponCode,
        yearlyPrice: yearlyPrice,
        func: addedCoupon,
      }),
    setLoading,
    requiredFields: [],
    initialValues: { couponCode: couponCode },
  });

  return {
    disabled,
    error,
    handleSubmit,
    setDisabled,
    setValue,
    values,
  };
};

export default CouponModalController;
