import React from "react";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";

export function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const dates = preFilteredRows.map((item) => {
    const date = moment(item.values[id], "DD-MM-YYYY");
    if (date.isValid()) {
      return date;
    }
    return undefined;
  });
  const datesValid = dates.filter((item) => item !== undefined);
  const minDate = moment.min(datesValid);
  const maxDate = moment.max(datesValid);

  return (
    <>
      <Flatpickr
        className="form-control"
        onChange={(date) => {
          if (date.length === 2) {
            setFilter([date[0], date[1]]);
          }
        }}
        options={{
          enable: [
            {
              from: minDate.toDate(),
              to: maxDate.toDate(),
            },
          ],
          mode: "range",
        }}
        placeholder="Calendario"
      />
    </>
  );
}
