export default function apiLeadSendDocument(
    token,
    leadId,
    type,
    message,
    email,
    resource,
    templates
) {
    const bearer = 'Bearer ' + token;
    return fetch(
        process.env.REACT_APP_CUSTOMER_APP_URL + `api/lead/send/document`,

        {
            method: 'POST',
            headers: {
                Authorization: bearer,
            },
            body: JSON.stringify({
                leadId: leadId,
                type: type,
                message: message,
                email: email,
                resource: resource,
                templates: templates,
            }),
        }
    ).then((response) => response.json());
}
