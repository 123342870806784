import React, { useEffect } from "react";

const Form = ({ initialValues, children }) => {
  const [values, setValues] = React.useState({ ...initialValues });

  const setValue = (name, value) => {
    if (name in values) {
      setValues({ ...values, [name]: value });
    }
  };

  const resetForm = () => {
    setValues(initialValues);
  };

  useEffect(() => {
    if (values === initialValues) {
      setValues(initialValues);
    }
  }, [values]);
  return children({ values, setValue, resetForm });
};

export default Form;
