export const ROLES = {
  SUPER_ADMIN: "ROLE_BROKER_SUPER_ADMIN",
  ADMIN: "ROLE_BROKER_ADMIN",
  EMPLOYEE: "ROLE_BROKER_EMPLOYEE",
};

export const ROLESTABLE = {
  ROLE_BROKER_SUPER_ADMIN: "broker.role.superAdmin",
  ROLE_BROKER_ADMIN: "broker.role.admin",
  ROLE_BROKER_EMPLOYEE: "broker.role.employee",
};

export const ROLESUSER = {
  ROLE_BROKER_SUPER_ADMIN: "brokerAdmin",
  ROLE_BROKER_ADMIN: "brokerAdmin",
  ROLE_BROKER_EMPLOYEE: "brokerEmployee",
};
