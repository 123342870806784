import React, { useContext, useEffect } from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import apiVelogicaLogin from "../../../api/request/Integrations/apiVelogicaLogin";

export interface VelogicaI {
    question: any;
}
const VelogicaQuestion: React.FC<VelogicaI> = ({ question }): JSX.Element => {
    const { leadId, token, setLoading, setTokenVelogica, setQuestion, tokenVelogica } = useContext(ContextGetlife);

    const fetchVelogica = async () => {
        try {
          const data = await apiVelogicaLogin(leadId, token);
          setTokenVelogica(data.access_token);
          setQuestion(question.questionId);
          setLoading(false);
        } catch (error) {
            setLoading(false);
        }
      };

    useEffect(() => {
        setLoading(true);
        fetchVelogica();
    }, [])

    return (
        <>
            {
                question.value === "answered" ? (<p>Complété</p>) : (<iframe
                    width={"100%"}
                    height={"450px"}
                    src={`${process.env.REACT_APP_VELOGICA_IFRAME}${tokenVelogica}`}
                  />)
            }
        </>
        
    )
}

export default VelogicaQuestion;