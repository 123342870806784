import numberFormat from "../../../../utils/numberFormat";
import Popover from "@mui/material/Popover";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core";
import ButtonPrimary from "../../../components/ButtonPrimary/ButtonPrimary";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import "./modalMoreInfo.scss";

const useStyles = makeStyles((theme) => ({
  popoverRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(100, 100, 100, 0.80)",
  },
}));

export default function ModalMoreInfo(props) {
  const {
    showModal,
    setShowModal,
    capital,
    insuranceType,
    downloadPDF,
    pdfElement,
    translate
  } = props;

  const classes = useStyles();

  return (
    <Popover
      open={showModal}
      onClose={() => setShowModal(false)}
      anchorReference={"none"}
      classes={{
        root: classes.popoverRoot,
      }}
    >
      <div className="modalMoreInfo">
        <h2 className="tittle">{translate("modal.conditions.title")}</h2>
        <CloseIcon
          onClick={() => setShowModal(false)}
          className="closeIcon"
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
        />
        <div className="intro">
          <p>
            {translate("modal.conditions.subtitle1")}
            <span>{translate("modal.conditions.subtitle2")}</span>
          </p>
        </div>
        <div className="box-info">
          <div className="general-conditions">
            <h4>{translate("modal.conditions.insuranceType.title")}</h4>
            <h4 className="infoSecondary">
              {insuranceType === "basic"
                ? translate("modal.conditions.insuranceType.death")
                : insuranceType === "premium"
                ? translate("modal.conditions.insuranceType.ipa")
                : ""}
            </h4>
            <h4>{translate("modal.conditions.capital.title")}</h4>
            <h4 className="infoSecondary">
              {" "}
              {numberFormat(capital, "capital")}
            </h4>
          </div>
          <div className="inclusions-box">
            <h4>{translate("modal.conditions.covered.title")}</h4>
            <div className="inclusions-box--check">
              <CheckCircleIcon />
              <p className="inclusions-box--check__text">{translate("modal.conditions.covered.text1")}</p>
            </div>
            <div className="inclusions-box--check">
              <CheckCircleIcon />
              <p className="inclusions-box--check__text">
                {translate("modal.conditions.covered.text2")}
              </p>
            </div>
            <div className="inclusions-box--check">
              <CheckCircleIcon />
              <p className="inclusions-box--check__text">
                {translate("modal.conditions.covered.text3")}
              </p>
            </div>
            <div className="inclusions-box--check">
              <CheckCircleIcon />
              <p className="inclusions-box--check__text">
                {translate("modal.conditions.covered.text4")}
              </p>
            </div>
            <div className="inclusions-box--check">
              <CheckCircleIcon />
              <p className="inclusions-box--check__text">{translate("modal.conditions.covered.text5")}</p>
            </div>
            <div className="inclusions-box--check">
              <CheckCircleIcon />
              <p className="inclusions-box--check__text">
                {translate("modal.conditions.covered.text6")}
              </p>
            </div>
            <div className="inclusions-box--check">
              <CheckCircleIcon />
              <p className="inclusions-box--check__text">
                {translate("modal.conditions.covered.text7")}
              </p>
            </div>
            <div className="inclusions-box--check">
              <CheckCircleIcon />
              <p className="inclusions-box--check__text">
                {translate("modal.conditions.covered.text8")}
              </p>
            </div>
          </div>
          <div className="exclusions-box">
            <h4>{translate("modal.conditions.notCovered.title")}</h4>
            <div className="exclusions-box--check">
              <CancelIcon />
              <p className="exclusions-box--check__text">
                {translate("modal.conditions.notCovered.text1")}
              </p>
            </div>
            <div className="exclusions-box--check">
              <CancelIcon />
              <p className="exclusions-box--check__text">
              {translate("modal.conditions.notCovered.text2")}
              </p>
            </div>
            <div className="exclusions-box--check">
              <CancelIcon />
              <p className="exclusions-box--check__text">
              {translate("modal.conditions.notCovered.text3")}
              </p>
            </div>
            <div className="exclusions-box--check">
              <CancelIcon />
              <p className="exclusions-box--check__text">
              {translate("modal.conditions.notCovered.text4")}
              </p>
            </div>
            <div className="exclusions-box--check">
              <p className="exclusions-box--check__textdownload">
              {translate("modal.conditions.notCovered.subtitle")}
              </p>
            </div>
          </div>
        </div>
        <div className="button-container-modal">
          <ButtonPrimary
            content={translate("modal.conditions.button")}
            className="upsell-personalization-add-button"
            onClick={downloadPDF}
          />
        </div>
        <a
          href
          id="downloadPDF"
          ref={pdfElement}
          rel="noreferrer"
          style={{ display: "none" }}
          target="_blank"
        >
          ''
        </a>
      </div>
    </Popover>
  );
}
