import moment from "moment";

const DateRangeController = ({ setEndDate, setIndexPage, setStartDate }) => {
  const dateFormat = "YYYY-MM-DD";

  const handleSelect = (value) => {
    setStartDate(moment().local().format(dateFormat));
    setEndDate(
      value.value < 0
        ? "2020-01-01"
        : moment().local().subtract(value.value, "day").format(dateFormat)
    );
    setIndexPage(1);
  };

  return {
    handleSelect,
  };
};

export default DateRangeController;
