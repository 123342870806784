// eslint-disable-next-line import/no-anonymous-default-export
export const columns_employees = [
  {
    type: "cell",
    header: "Email",
    ref: "email",
    elementType: "text",
  },
  {
    type: "cell",
    header: "Nombre",
    ref: "name",
    elementType: "text",
  },
  {
    type: "cell",
    header: "Teléfono",
    ref: "phone",
    elementType: "text",
  },
  {
    type: "cell",
    header: "Rol",
    ref: "rol",
    elementType: "text",
  },
  {
    type: "cell",
    header: "Fecha creación",
    ref: "createdAt",
    sortable: true,
    elementType: "text",
  },
  // Temporal until can show edit button.
  {
    type: "button",
    text: "Editar",
    ref: "editar",
    elementType: "button",
  },
];

export const errors = {};
