import * as React from "react";
import HttpLeadRepository from "../api/request/Lead/lead.service";
import CapitalLimitsResponse, {
  AvailableTraits,
  CapitalLimits,
} from "../api/request/Tarifier/Model/Response/CapitalLimitsResponse.model";
import HttpTarifierRepository from "../api/request/Tarifier/tarifier.service";
import { Notification } from "../contexts/ContextGetlife";

interface UseCapitalLimitsProps {
  setCapital?: (capital: number) => void;
  setShowNotification: (notification: Notification | boolean) => void;
  token: string;
  translate: (key: string) => string;
}

const useCapitalLimits = ({
  setCapital,
  setShowNotification,
  token,
  translate,
}: UseCapitalLimitsProps) => {
  const [limits, setLimits] = React.useState<CapitalLimits>(
    {} as CapitalLimits
  );
  const [traits, setTraits] = React.useState<AvailableTraits>(
    {} as AvailableTraits
  );
  const max = React.useRef(0);
  const [leadId, setLeadId] = React.useState<string>();
  const [birthdate, setBirthdate] = React.useState<string>();

  const leadRepository = new HttpLeadRepository(token);
  const tarifierRepository = new HttpTarifierRepository(token);

  const fetchCapitalLimits = async () => {
    try {
      let response: CapitalLimitsResponse = leadId
        ? await leadRepository.getLeadBoundaries(leadId)
        : await tarifierRepository.getCapitalLimits(birthdate || "");
      const { minCapital, maxCapital, availableTraits } = response;
      setLimits({
        maxCapital,
        minCapital,
        step: maxCapital === 500000 ? 25000 : 10000,
      });
      setCapital && max.current !== maxCapital && setCapital(maxCapital / 2);
      max.current = maxCapital;
      setTraits({
        DEATH: availableTraits.DEATH,
        DISABILITY: availableTraits.DISABILITY,
      });
      if (availableTraits.DEATH === false && availableTraits.DISABILITY === false) {
        setShowNotification({
          message: translate("errors.limits.ageRange"),
          status: true,
          type: "error",
          autoHideDuration: 5000,
        });
      }
    } catch (error: any) {
      setShowNotification({
        message: translate(`errors.dashboard.${error.message}`),
        status: true,
        type: "error",
      });
    }
  };

  React.useEffect(() => {
    (async () => {
      if (birthdate || leadId) {
        await fetchCapitalLimits();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthdate, leadId]);

  return { limits, traits, setBirthdate, setLeadId };
};

export default useCapitalLimits;
