import { useContext } from "react";
import Modal from "../../Rebranding/organisms/Modal/Modal.component";
import DownloadableFiles from "./DownloadableFiles";
import "./DownloadableFilesModal.scss";
import { I18nContext } from "../../../contexts/i18n.context";

interface DownloadableFilesModalProps {
  open: boolean;
  setOpen: (open: boolean) => null;
  downloadablePdfs: [
    {
      label: string;
      href: string;
    }
  ];
}

const DownloadableFilesModal = ({
  open,
  setOpen,
  downloadablePdfs,
}: DownloadableFilesModalProps): JSX.Element => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  return (
    <Modal
      showModal={open}
      setShowModal={setOpen}
      ModalTitle={
        <h3 className="downloadableFilesModalTitle">
          {translate("downloadableFiles.modal.title")}
        </h3>
      }
      ModalBody={
        <div className="downloadableFilesModalBody">
          <p className="downloadableFilesModalBody__text">
            {translate("downloadableFiles.modal.body")}
          </p>
          <DownloadableFiles downloadablePdfs={downloadablePdfs} />
        </div>
      }
    />
  );
};

export default DownloadableFilesModal;
