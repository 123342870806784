import './question.scss';

export default function QuestionAlert(props) {

	return (
		<div className='question-mainContainer'>
			<div className='question-container'>
				<p className='question-questionalert'>
					Lo sentimos, actualmente no podemos ofrecer cobertura para este perfil de riesgo concreto de forma inmediata.
					<br/>Por favor contacta con tu gestor o con el centro de soporte.
				</p>
			</div>
		</div>
	);
}
