import * as React from "react";
import { get, patch, post, put } from "../../core/HTTPService.service";
import BrokerRequest from "./Model/Request/BorkerRequest.model";
import BrokerCompleteRegistrationRequest from "./Model/Request/BrokerCompleteRegistrationResquest.model";
import BrokerUpdateRequest from "./Model/Request/BrokerUpdateRequest.model";
import BrokerCompanyInfoResponse from "./Model/Response/BrokerCompanyInfoResponse.model";
import BrokerGeneralResponse from "./Model/Response/BrokerGeneralResponse.model";
import BrokerLoginResponse from "./Model/Response/BrokerLoginResponse.model";
import BrokerResponse from "./Model/Response/BrokerResponse.model";
import EarningResponse from "./Model/Response/EarningResponse.model";

export default class HttpBrokerRepository {
  private readonly API_URL_BROKER:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/broker`;

  private readonly API_URL_BROKER_V2:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/v2/broker`;

  private readonly API_URL_BROKER_LOGIN:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/login`;

  private readonly API_URL_FULLSTORY:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMERS_APP_URL}fullStory`;

  private readonly header: { Authorization: string } | undefined;

  private readonly brokerId: string | undefined;

  constructor(brokerId?: string, token?: string) {
    this.brokerId = brokerId;
    this.header = token ? { Authorization: `Bearer ${token}` } : undefined;
  }

  getBroker = async (token: string): Promise<BrokerResponse> => {
    let broker: BrokerResponse | null;
    try {
      const response: BrokerResponse = await get(`${this.API_URL_BROKER}`, {
        Authorization: `Bearer ${token}`,
      });
      broker = response;
    } catch (e) {
      throw e;
    }
    return broker;
  };

  getBrokerBySlug = async (slug: string, search?: string): Promise<BrokerResponse> => {
    let broker: BrokerResponse | null;
    let url : string;
    if (search) {
      const agent = search.split('=')[0];
      const email = encodeURIComponent(search.split('=')[1]);
      const completeParam = agent.concat('=', email);
      url = `${this.API_URL_BROKER}BySlug/${slug}${completeParam}`
    } else {
      url =`${this.API_URL_BROKER}BySlug/${slug}`
    }
    
    try {
      const response: BrokerResponse = await get(
        url
      );
      broker = response;
    } catch (e) {
      throw e;
    }
    return broker;
  };

  getCompanyInformation = async (): Promise<BrokerCompanyInfoResponse> => {
    let company: BrokerCompanyInfoResponse | null;
    try {
      const response: BrokerCompanyInfoResponse = await get(
        `${this.API_URL_BROKER}/company_info`,
        this.header
      );
      company = response;
    } catch (error) {
      throw error;
    }
    return company;
  };

  getEarnings = async (
    startDate: string,
    endDate: string
  ): Promise<EarningResponse> => {
    let earning: EarningResponse | null = null;
    try {
      const response: EarningResponse = await get(
        `${this.API_URL_BROKER_V2}/earnings/${startDate}/${endDate}`,
        this.header
      );
      earning = response;
    } catch (e) {
      throw e;
    }

    return earning;
  };

  completeRegistration = async (
    data: BrokerCompleteRegistrationRequest
  ): Promise<void> => {
    try {
      await post(
        `${this.API_URL_BROKER}/complete-registration`,
        data,
        this.header
      );
    } catch (error) {
      throw error;
    }
  };

  login = async (data: {
    username: string;
    password: string;
  }): Promise<BrokerLoginResponse> => {
    let brokerData: BrokerLoginResponse | null = null;
    try {
      const response: BrokerLoginResponse = await post(
        `${this.API_URL_BROKER}/login`,
        {
          username: data.username,
          password: data.password,
        }
      );
      brokerData = response;
    } catch (e) {
      throw e;
    }
    return brokerData;
  };

  loginOtp = async (value: string, type: string): Promise<void> => {
    let type_translated = type === "tel" ? "SMS" : "EMAIL";
    try {
      await post(`${this.API_URL_BROKER_LOGIN}/request-otp`, {
        value: value,
        method: type_translated,
      });
    } catch (e) {
      throw e;
    }
  };

  loginByOtp = async (otp: string): Promise<BrokerLoginResponse> => {
    let broker: BrokerLoginResponse | null = null;
    try {
      const response: BrokerLoginResponse = await get(
        `${this.API_URL_BROKER_LOGIN}/guest-employee/${otp}`
      );
      broker = response;
    } catch (e) {
      throw e;
    }
    return broker;
  };

  loginSubmitOtp = async (value: string): Promise<BrokerLoginResponse> => {
    let broker: BrokerLoginResponse | null = null;
    try {
      const response: BrokerLoginResponse = await post(
        `${this.API_URL_BROKER_LOGIN}/submit-otp`,
        {
          code: value,
        }
      );
      broker = response;
    } catch (e) {
      throw e;
    }
    return broker;
  };

  patchQuestionnaire = async (): Promise<{ status: string }> => {
    let data: { status: string };
    try {
      const response: { status: string } = await patch(
        `${this.API_URL_BROKER}/questionnaire-completed`,
        { brokerEmployeeId: this.brokerId },
        this.header
      );
      data = response;
    } catch (e) {
      throw e;
    }
    return data;
  };

  postBroker = async (data: BrokerRequest): Promise<BrokerGeneralResponse> => {
    let broker: BrokerGeneralResponse | null = null;
    if (data.idDgsfp) data.idDgsfp.replace(/[^\w]/g, "");
    if (!data.workPhone) {
      data.workPhone = null;
    }
    try {
      const response: BrokerGeneralResponse = await post(
        `${this.API_URL_BROKER}`,
        data
      );
      broker = response;
    } catch (e) {
      throw e;
    }

    return broker;
  };

  resetEmail = async (email: string): Promise<string> => {
    let result: string | null;
    try {
      const response: string = await get(
        `${this.API_URL_BROKER}/reset/${email}`
      );
      result = response;
    } catch (e) {
      throw e;
    }
    return result;
  };

  updateBroker = async (
    data: BrokerUpdateRequest
  ): Promise<BrokerCompanyInfoResponse> => {
    let result: any | null;
    try {
      const response: string = await put(
        `${this.API_URL_BROKER}/update_company_info`,
        data,
        this.header
      );
      result = response;
    } catch (e) {
      throw e;
    }
    return result;
  };

  getUserFullStory = async (id: string) => {
    try {
      const response = await get(
        `${this.API_URL_FULLSTORY}/getUser/${id}`,
        this.header
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  updateFullStoryUser = async (id:string, body: {}) => {
    try {
      const response = await post(
        `${this.API_URL_FULLSTORY}/updateUser/${id}`,
          body
      );
      return response;
    } catch (e) {
      throw e;
    }
  };
}
