import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ButtonPrimary from "../components/buttons/ButtonPrimary";
import WhiteButton from "../components/buttons/WhiteButton";
import InputGetLife from "../components/inputGetLife/InputGetLife";
import TipContainer from "../components/tipContainer/TipContainer";

import FormCalcPrice from "../components/formCalcPrice/formCalcPrice";
import Share from "./../../assets/img/share.svg";
import "./dashboardMain.scss";
import "../components/buttons/css/buttonPrimary.scss";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import numberFormat from "../../utils/numberFormat";

import Form from "../components/Form/Form";
import prepareListDaysOptions from "../../utils/prepareListDaysOptions";
import prepareListMonthsOptions from "../../utils/prepareListMonthsOptions";
import calcListYearOptions from "../../utils/calcListYearOptions";
import ModalSendEmail from "../components/ModalSendEmail/ModalSendEmail";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import InfoMessage from "../Rebranding/atoms/InfoMessage/InfoMessage.component";
import HttpLeadRepository from "../../api/request/Lead/lead.service";
import { I18nContext } from "../../contexts/i18n.context";
import Typography from "../Rebranding/atoms/Typography/Typography.component";
import { sanitizeCapital } from "../projects/controllers/EditProject.controller";
import FormCalcPriceFR from "../components/formCalcPrice/formCalcPriceFR";
import { GlobalIcon } from "get-life-storybook-ts";
import DownloadableFilesModal from "../components/DownloadableFiles/DownloadableFilesModal";

export default function DashboardMain(props) {
  const {
    broker,
    buttonCalculate,
    capital,
    capitalLimits,
    emailToSend,
    error,
    errorMessage,
    handleInsurancePrice,
    handleModalSend,
    handleSubmitOrganic,
    initialValues,
    insurancePrice,
    notOwner,
    sendEmailDocument,
    setBirthDate,
    setCapital,
    setInsuranceType,
    setNotOwner,
    setShowAlerts,
    setButtonCalculate,
    setShowModalSend,
    setShowResults,
    shareText,
    showAlerts,
    showModalSend,
    showResults,
    traits,
    workingProfesionList,
    downloadablePdfs
  } = props;

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const { leadId, status, setShowNotification, token } =
    useContext(ContextGetlife);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const leadRepository = new HttpLeadRepository(token);
  const navigate = useNavigate();
  const navigateToQuestions = async (resetForm) => {
    try {
      await leadRepository.questionsByLeadId(leadId);
      handleInsurancePrice(false);
      navigate(`/questions`);
      handleResetForm(resetForm);
    } catch (error) {
      setShowNotification({
        message: translate("errors.global.notUserActivated"),
        status: true,
        type: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        link: (
          <a
            style={{
              textDecoration: "none",
              color: "white",
            }}
            href="mailto:broker@life5.com"
          >
            {" "}
            broker@life5.com
          </a>
        ),
      });
    }
  };
  const [copiedClient, setCopiedClient] = useState(false);

  const handleChangeBirthDate = (values) => {
    if (!!values.day && !!values.month && !!values.year) {
      setBirthDate(
        `${values.year.value}-${values.month.value}-${values.day.value}`
      );
    }
  };

  const handleMoreCapital = (values, setValue) => {
    let newValue = values.capital + capitalLimits.step;
    if (newValue <= capitalLimits.maxCapital) {
      setValue("capital", newValue);
      setCapital(newValue);
    } else {
      setValue("capital", capitalLimits.maxCapital);
      setCapital(capitalLimits.maxCapital);
    }
  };
  const handleLessCapital = (values, setValue) => {
    let newValue = values.capital - capitalLimits.step;

    if (newValue >= capitalLimits.minCapital) {
      setValue("capital", newValue);
      setCapital(newValue);
    } else {
      setValue("capital", capitalLimits.minCapital);
      setCapital(capitalLimits.minCapital);
    }
  };
  const handleChangeCapital = (data, setValue) => {
    let newCapital = sanitizeCapital(data);
    if (!newCapital || newCapital < capitalLimits.minCapital) {
      newCapital = capitalLimits.minCapital;
    } else if (newCapital > capitalLimits.maxCapital) {
      newCapital = capitalLimits.maxCapital;
    }

    setValue("capital", newCapital);
    setCapital(newCapital);
  };

  const handleResetForm = (resetFrom) => {
    resetFrom();
    setCapital("");
    setNotOwner(false);
    setShowResults(false);
    setButtonCalculate("calculate");
    setShowAlerts({
      maxCapital: false,
      disability: false,
      recalcMaxCapital: false,
      recalcDisability: false,
    });
  };

  const setValuesCapital = (values, setValue) => {
    if (values.capital !== capital) {
      setValue("capital", capital);
    }
  };

  const [downloadableOpen, setDownloadableOpen] = useState(false);

  return (
    <div className="dashboard-mainContainer">
      <div className="dashboard-container">
        <div className="dashboard-leftContainer">
          {status === "activation_pending" && (
            <div className="dashboard--alert">
              <WarningAmberRoundedIcon />
              <p className="dashboard--alert__text">
                <span>{translate("dashboard.signaturePending.title")}</span>
                <br></br>
                {translate("dashboard.signaturePending.text")}
              </p>
            </div>
          )}
          <Typography
            variant="h1"
            text={`${translate("dashboard.welcome.title")} ${!!broker && !!broker.name ? broker.name : ""}`}
          />
          <article className="dashboard-topTipsContainer">
            <Typography variant="paragraph" text={translate("dashboard.broker.title")} />
            <TipContainer
              header={translate("dashboard.broker.title1")}
              content={translate("dashboard.broker.text1")}
            />
            <TipContainer
              header={translate("dashboard.broker.title2")}
              content={translate("dashboard.broker.text2")}
            />
            <TipContainer
              header={translate("dashboard.broker.title3")}
              content={translate("dashboard.broker.text3")}
            />
            <TipContainer
              header={translate("dashboard.broker.title4")}
              content={translate("dashboard.broker.text4")}
            />
            <TipContainer
              header={translate("dashboard.broker.title5")}
              content={translate("dashboard.broker.text5")}
            />
          </article>

          <article className="dashboard-bottomTipsContainer">
            <Typography variant="paragraph" text={translate("dashboard.customer.title")} />
            <TipContainer
              header={translate("dashboard.customer.title1")}
              content={translate("dashboard.customer.text1")}
            />
            <TipContainer
              header={translate("dashboard.customer.title2")}
              content={translate("dashboard.customer.text2")}
            />
            <TipContainer
             header={translate("dashboard.customer.title3")}
             content={translate("dashboard.customer.text3")}
            />
            <TipContainer
              header={translate("dashboard.customer.title4")}
              content={translate("dashboard.customer.text4")}
            />
          </article>

          <div className="dashboard-conditionalWellcome">
            <h1>{translate("dashboard.welcome.title")} {broker ? broker.name : ""}</h1>
          </div>
          {(DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it") && (
            <>
              <div className="dashboard-rightContainerTopHeader">
                <p>{translate("dashboard.welcome.invite")}</p>
              </div>
              <div className="dashboard-rightCopyLinkContainer">
                <div className="dashboard-copyLinkContainer">
                  <InputGetLife readonly={true} answer={broker.landing} />
                  <div className="dashboard-buttonLinkContainer">
                    <CopyToClipboard
                      text={broker.landing}
                      onCopy={() => setCopiedClient(true)}
                    >
                      <ButtonPrimary content={translate("dashboard.button.copy")} />
                    </CopyToClipboard>
                    {copiedClient ? (
                      <span className={"tooltip-copy"}>Copiado</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="dashboard-rightContainer">
          <Form initialValues={{ ...initialValues }}>
            {({ values, setValue, resetForm }) => {
              handleChangeBirthDate(values);
              setValuesCapital(values, setValue);
              return (
                <>
                  <div className="dashboard-rightContainerBottomHeader">
                    <div className="dashboard-rightContainerBottomHeader-left">
                      <p>{translate("calculator.title")}</p>
                    </div>
                    <div className="dashboard-rightContainerBottomHeader-right">
                      <RestartAltOutlinedIcon />
                      <p onClick={() => handleResetForm(resetForm)}>
                      {translate("calculator.button.retry")}
                      </p>
                    </div>
                  </div>
                  {
                    (DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it") ?
                    (
                      <FormCalcPrice
                        buttonCalculate={buttonCalculate}
                        capital={capital}
                        capitalLimits={capitalLimits}
                        death={traits.DEATH}
                        disability={traits.DISABILITY}
                        error={error}
                        errorMessage={errorMessage}
                        translate={translate}
                        handleSubmitOrganic={handleSubmitOrganic}
                        lessCapital={handleLessCapital}
                        moreCapital={handleMoreCapital}
                        optionsDays={prepareListDaysOptions()}
                        optionsMonths={prepareListMonthsOptions(translate)}
                        optionsYears={calcListYearOptions()}
                        setCapital={handleChangeCapital}
                        setInsuranceType={setInsuranceType}
                        setValue={setValue}
                        showAlerts={showAlerts}
                        values={values}
                      />
                    ) : (
                      <FormCalcPriceFR
                        buttonCalculate={buttonCalculate}
                        capital={capital}
                        capitalLimits={capitalLimits}
                        death={traits.DEATH}
                        disability={traits.DISABILITY}
                        error={error}
                        errorMessage={errorMessage}
                        translate={translate}
                        handleSubmitOrganic={handleSubmitOrganic}
                        lessCapital={handleLessCapital}
                        moreCapital={handleMoreCapital}
                        optionsDays={prepareListDaysOptions()}
                        optionsMonths={prepareListMonthsOptions(translate)}
                        optionsYears={calcListYearOptions()}
                        setCapital={handleChangeCapital}
                        workingProfesionList={workingProfesionList}
                        setValue={setValue}
                        showAlerts={showAlerts}
                        values={values}
                      />
                    )
                  }

                  <div id="result">
                    {notOwner === true && (
                      <>
                        <div className="dashboard-formFooter notOwner">
                          <div className="dashboard-formFooterDataContainer">
                            <div className="dashboard-formFooterData">
                              <p>
                                 {`${translate("dashboard.emailNotOwner.text1")} ${values.email} ${"dashboard.emailNotOwner.text2"}`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {showResults && !notOwner && (
                      <>
                        <div className="dashboard-rightFormContainerFooter">
                          <div className="dashboard-rightContainerBottomHeader-right">
                            <RestartAltOutlinedIcon />
                            <p onClick={() => handleResetForm(resetForm)}>
                            {translate("calculator.button.retry")}
                            </p>
                          </div>
                        </div>
                        <DownloadableFilesModal
                          open={downloadableOpen}
                          setOpen={setDownloadableOpen}
                          downloadablePdfs={downloadablePdfs}
                        />
                        <div className="dashboard-formFooter">
                          <div className="dashboard-formFooterDataContainer">
                            <div className="dashboard-formFooterData">
                              <p>{translate("dashboard.resume.insuranceType")}</p>
                              <p>
                                {insurancePrice.insuranceType === "basic"
                                  ? translate("dashboard.insuranceType.basic")
                                  : translate("dashboard.insuranceType.premium")}
                              </p>
                            </div>
                            <div className="dashboard-formFooterData">
                              <p>{translate("dashboard.resume.yearlyPrice")}</p>

                              <p>
                                {numberFormat(
                                  insurancePrice.annualPrice,
                                  "price"
                                )}
                              </p>
                            </div>
                            <div className="dashboard-formFooterData">
                              <p>{translate("dashboard.resume.monthlyPrice")}</p>

                              <p>
                                {numberFormat(
                                  insurancePrice.monthlyPrice,
                                  "price"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="dashboard-buttonWhiteContainer">
                            <WhiteButton
                              content={translate("dashboard.button.contract")}
                              onClick={() => navigateToQuestions(resetForm)}
                            />
                          </div>

                          <InfoMessage
                            error={translate("dashboard.resume.infoMessage")}
                            type="error"
                            className="notify-not-emai"
                          />
                        </div>
                        <div className="dashboard-formFooterGreyButContainer">
                          <div
                            role="button"
                            onClick={() => setDownloadableOpen(true)}
                            className="dashboard-formGreyButton"
                          >
                            <p>{translate("dashboard.resume.downloadableFiles")}</p>
                            <GlobalIcon iconName="DownloadCloudIcon" size="XS" color="#424242" />
                          </div>
                          <div
                            className="dashboard-formGreyButton"
                            onClick={() => handleModalSend()}
                          >
                            <p>{shareText}</p>
                            <img src={Share} alt="Enviar email" />
                          </div>
                          <ModalSendEmail
                            showModal={showModalSend}
                            setShowModal={setShowModalSend}
                            emailToSend={emailToSend}
                            sendEmail={(email, text) =>
                              sendEmailDocument(
                                email,
                                text,
                                (DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it") ? values.insuranceType.value : "DEVIS"
                              )
                            }
                          />
                          <a
                            href
                            target="_blank"
                            id="downloadPDF"
                            style={{
                              display: "none",
                            }}
                          >
                            {" "}
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                </>
              );
            }}
          </Form>
        </div>
      </div>
    </div>
  );
}
