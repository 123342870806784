import React, { useState } from "react";
import TagManager from "react-gtm-module";
import * as FullStory from '@fullstory/browser';
import { IntentType } from "../api/request/Lead/Model/Response/CarrierPricesResponse.model";

export type CoverageType = "F" | "FIPA";

export type GenderTag = "M" | "F";

interface GoogleTagManager {
  event?: string;
  page?: string;
  user_id?: string;
  userType?:
    | "brokerAdmin"
    | "brokerEmployee"
    | "getlifeEmployee"
    | "leadStatus";
  brokerId?: string | number;
  brokerEmployeeId?: string | number;
  brokerName?: string;
  leadId?: string | number;
  calculatedPremium?: string | number;
  paymentMethod?:
    | "cardDirect"
    | "cardLinkpaymentSms"
    | "cardLinkpaymentEmail"
    | "sepa";
  birthDate?: string;
  gender?: GenderTag;
  calculatedCapital?: string | number;
  finalCapital?: string | number;
  intent?: IntentType;
  postalCode?: string | number;
  coverage?: CoverageType;
  answeredQ?: string;
  answers?: string;
  email?: string;
  phone?: string;
  utm_source?: string | null;
  utm_campaign?: string | null;
  utm_content?: string | null;
  utm_medium?: string | number | null;
  utm_term?: string | null;
  utm_test?: string | null;
  gclid?: string | null;
  fbclid?: string | null;
}

interface GoogleContextProps {
  handleChangeDataLayer: (value: GoogleTagManager) => void;
}

export const GoogleContext = React.createContext({} as GoogleContextProps);

export const GoogleProvider = ({ children }: any) => {
  if (process.env.REACT_APP_MODE !== "DEV") {
    const tagManagerArgs = {
      gtmId: "GTM-PL83KZL",
    };
    TagManager.initialize(tagManagerArgs);
    FullStory.init({ orgId: 'o-19FZSH-na1' });
  }
  const [dataLayer, setDataLayer] = useState<GoogleTagManager>(
    {} as GoogleTagManager
  );

  const getUserID = (): string => {
    const indexGID: number = document.cookie.search("_gid");
    const gid: string[] | boolean = indexGID !== -1 ? document.cookie
      .substring(indexGID)
      .split(";")[0]
      .split(".") : false
    const userID: string = gid ? gid[gid.length - 2].concat(".", gid[gid.length - 1]) : "";

    return userID;
  };

  const handleChangeDataLayer = (data?: GoogleTagManager): void => {
    setDataLayer({ user_id: getUserID(), ...dataLayer, ...data });
    TagManager.dataLayer({ dataLayer: { user_id: getUserID(), ...data } });
  };

  const values = {
    handleChangeDataLayer,
  };

  return (
    <GoogleContext.Provider value={values}>{children}</GoogleContext.Provider>
  );
};
