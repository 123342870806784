async function apiLeadQuestionCarrierGet(leadId, brokerId, carrierId, token) {
	const bearer = 'Bearer ' + token;
	const response = await fetch(
		process.env.REACT_APP_CUSTOMER_APP_URL +
			`api/lead/question/${brokerId}/${leadId}/${carrierId}`,
		{
			headers: {
				Authorization: bearer,
			},
		}
	);
	return await response.json();
}


export default apiLeadQuestionCarrierGet;