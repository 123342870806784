import { errorMessage } from "../../utils/error";
import { ROLESTABLE } from "../../utils/roles";
import ButtonPrimary from "../components/ButtonPrimary/ButtonPrimary";
import InputGeneral from "../components/Input/Input";
import Modal from "../Rebranding/organisms/Modal/Modal.component";
import SelectGeneral from "../components/SelectGeneral/SelectGeneral";
import { useCloseByKey } from "../../utils/closeByKey";
import KEYS from "../../utils/keyboardKeys";

const rolOptions = (translate) => Object.entries(ROLESTABLE)
  .slice(0)
  .map((rol) => ({
    label: translate(rol[1]),
    value: rol[0],
  }));

const EditEmployee = ({
  values,
  setValue,
  showModal,
  setShowModal,
  handleSubmit,
  id,
  error,
  translate
}) => {
  useCloseByKey({
    key: KEYS.KEY_ENTER,
    implementedFunction: () => {
      if (!!values) {
        handleSubmit(values);
      }
    },
    setClose: setShowModal,
    value: values,
  });
  useCloseByKey({ key: KEYS.KEY_ESC, setClose: setShowModal });
  return (
    <Modal
      id={id}
      showModal={showModal}
      idModal="employee-page"
      setShowModal={setShowModal}
      ModalTitle={
        <h1 className="employees-modal-title">{translate("editEmployee.modal.title")}</h1>
      }
      ModalBody={
        <div className="employees-body-modal">
          <div className="employees-details">
            <div className="employees-input">
              <InputGeneral
                editValue={values["name"]}
                error={errorMessage(error, "name")}
                id="input-name"
                label={translate("editEmployee.modal.name")}
                name="name"
                setter={(newValue) => setValue("name", newValue)}
              />
            </div>
            <div className="employees-input">
              <InputGeneral
                editValue={values["lastname"]}
                error={errorMessage(error, "lastname")}
                id="input-lastname"
                label={translate("editEmployee.modal.lastName")}
                name="lastname"
                setter={(newValue) => setValue("lastname", newValue)}
              />
            </div>
            <div className="employees-input">
              <SelectGeneral
                editValue={values["rol"]}
                error={errorMessage(error, "rol")}
                label={translate("editEmployee.modal.rol")}
                handleValues={(newValue) => setValue("rol", newValue)}
                toMap={rolOptions(translate)}
                placeholder={translate("editEmployee.modal.rol")}
              />
            </div>
          </div>
          <div className="employees-details">
            <div className="employees-input">
              <InputGeneral
                editValue={values["email"]}
                error={errorMessage(error, "email")}
                id="input-email"
                label={translate("editEmployee.modal.email")}
                name="email"
                setter={(newValue) => setValue("email", newValue)}
              />
            </div>
            <div className="employees-input">
              <InputGeneral
                editValue={values["phone"]}
                error={errorMessage(error, "phone")}
                id="input-phone"
                label={translate("editEmployee.modal.phone")}
                name="phone"
                setter={(newValue) => setValue("phone", newValue)}
              />
            </div>
          </div>
          <ButtonPrimary
            content={translate("editEmployee.modal.button")}
            className="employees-modal-button"
            onClick={() => handleSubmit(values)}
          />
        </div>
      }
    />
  );
};

export default EditEmployee;
