/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import TicketListMain from "./TicketListMain";
import moment from "moment";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import HttpTicketRepository from "../../api/request/Tickets/ticket.service";

export default function Projects() {
  const { token } = useContext(ContextGetlife);

  const [data, setData] = useState(false);
  const [rangue, setRangue] = useState(false);
  const [statuses, setStatuses] = useState([]);

  const ticketRepository = new HttpTicketRepository(token);

  const handleRangue = (e) => {
    setRangue(e);
  };

  useEffect(() => {
    const endDate = moment().local().format("DD-MM-YYYY");
    const startDate = moment().local().subtract(15, "day").format("DD-MM-YYYY");
    fillTickets(startDate, endDate);
  }, []);

  const fillTickets = (startDate, endDate) => {
    ticketRepository.getTicket(startDate, endDate).then((data) => {
      setData(data.tickets);
      setStatuses(data.status);
    });
  };

  useEffect(() => {
    let endDate = moment().local().format("DD-MM-YYYY");
    let startDate = moment().local().subtract(15, "day").format("DD-MM-YYYY");

    if (rangue.label === "Hoy") {
      startDate = moment().local().format("DD-MM-YYYY");
    } else if (rangue.label === "Últimos 7 días") {
      startDate = moment().local().subtract(7, "day").format("DD-MM-YYYY");
    } else if (rangue.label === "Últimos 15 días") {
      startDate = moment().local().subtract(15, "day").format("DD-MM-YYYY");
    } else if (rangue.label === "Últimos 30 días") {
      startDate = moment().local().subtract(30, "day").format("DD-MM-YYYY");
    } else if (rangue.label === "Último trimestre") {
      startDate = moment().local().subtract(3, "month").format("DD-MM-YYYY");
    } else if (rangue.label === "Últimos 6 meses") {
      startDate = moment().local().subtract(6, "month").format("DD-MM-YYYY");
    } else if (rangue.label === "Último año") {
      startDate = moment().local().subtract(1, "year").format("DD-MM-YYYY");
    } else if (rangue.label === "Mostrar todo") {
      startDate = moment().local().subtract(1, "year").format("DD-MM-YYYY");
    }

    fillTickets(startDate, endDate);
  }, [rangue]);

  return (
    <>
      <TicketListMain
        data={data}
        handleRangue={handleRangue}
        statuses={statuses}
      />
    </>
  );
}
