import { errorMessage } from "../../../../utils/error";
import InputGeneral from "../../molecules/InputGeneral/InputGeneral.component";
import { FormPersonalDataProps } from "./FromInterface.model";

const FormPersonalData = ({
  emailDisabled,
  error,
  setValue,
  translate,
  values,
  disabled = false,
}: FormPersonalDataProps): JSX.Element => {

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;
  return (
    <>
      <InputGeneral
        disabled={disabled}
        editValue={values["name"]}
        label={translate("global.name")}
        setter={(newValue: string) => setValue("name", newValue)}
        name="name"
        error={errorMessage(error, "name")}
      />

      <InputGeneral
        disabled={disabled}
        editValue={values["lastName"]}
        label={translate("global.lastName1")}
        setter={(newValue: string) => setValue("lastName", newValue)}
        name="lastName"
        error={errorMessage(error, "lastName")}
      />

      {(DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it") && <InputGeneral
        disabled={disabled}
        editValue={values["lastName2"]}
        label={translate("global.lastName2")}
        setter={(newValue: string) => setValue("lastName2", newValue)}
        name="lastName2"
        error={errorMessage(error, "lastName2")}
      />}

      <InputGeneral
        disabled={disabled || emailDisabled}
        readOnly={emailDisabled}
        editValue={values["email"]}
        label={translate("global.email")}
        setterOnBlur={(newValue: string) => {
          setValue("email", newValue);
        }}
        name="email"
        error={errorMessage(error, "email")}
      />

      <InputGeneral
        disabled={disabled}
        editValue={values["phone"]}
        type="number"
        label={translate("global.phone")}
        setterOnBlur={(newValue: string) => setValue("phone", newValue)}
        name="phone"
        error={errorMessage(error, "phone")}
      />
    </>
  );
};

export default FormPersonalData;
