import * as React from "react";
import { ContextGetlife } from "../../../../contexts/ContextGetlife";
import { I18nContext } from "../../../../contexts/i18n.context";
import useForm from "../../../../hooks/useForm.hook";

export interface PostStackQuestionControllerProps {
  initialValues: any;
  fetch: any;
  requiredFields: any;
}

const PostStackQuestionController = ({
  initialValues,
  fetch,
  requiredFields,
}: PostStackQuestionControllerProps) => {
  const {
    state: { translate },
  } = React.useContext(I18nContext);
  const { setLoading } = React.useContext(ContextGetlife);
  const { values, setValue, error, handleSubmit, setValues } = useForm({
    initialValues: initialValues,
    setLoading,
    fetch,
    requiredFields,
  });

  return {
    error,
    handleSubmit,
    setValue,
    setValues,
    translate,
    values,
  };
};

export default PostStackQuestionController;
