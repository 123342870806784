import { errorMessage } from "../../../../utils/error";
import Button from "../../atoms/Buttons/ButtonBase/Button.component";
import InputGeneral from "../../molecules/InputGeneral/InputGeneral.component";

interface FormEmployeeProps {
  error: any;
  handleSubmit: () => void;
  setValue: (name: string, value: any) => void;
  translate: (value: string) => string;
  values: any;
}

const FormEmployee = ({
  error,
  handleSubmit,
  setValue,
  translate,
  values,
}: FormEmployeeProps): JSX.Element => {
  return (
    <>
      <InputGeneral
        editValue={values["name"]}
        label={translate("global.name")}
        setter={(newValue: string) => setValue("name", newValue)}
        name="name"
        error={errorMessage(error, "name")}
      />
      <InputGeneral
        editValue={values["lastname"]}
        label={translate("global.lastName")}
        setter={(newValue: string) => setValue("lastname", newValue)}
        name="lastname"
        error={errorMessage(error, "lastname")}
      />
      <InputGeneral
        editValue={values["phone"]}
        label={translate("global.phone")}
        type="number"
        setter={(newValue: string) => setValue("phone", newValue)}
        name="phone"
        error={errorMessage(error, "phone")}
      />
      <Button buttonType="primary" onClick={() => handleSubmit()}>
        {translate("employees.buttons.newEmployee")}
      </Button>
    </>
  );
};

export default FormEmployee;
