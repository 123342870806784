import * as React from "react";
import { IconProps } from "./IconProps.interface";

const PhoneIcon: React.FC<IconProps> = ({
  width = "16",
  height = "16",
  color = "#fff",
  className,
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.429 11.555C6.938 14.08 9.969 16 12.437 16c1.103 0 2.076-.376 2.86-1.242.45-.507.703-1.07.703-1.643 0-.49-.188-.956-.654-1.29l-2.525-1.798c-.416-.295-.76-.425-1.07-.425-.4 0-.752.228-1.136.612l-.613.605a.425.425 0 0 1-.319.13.807.807 0 0 1-.36-.097c-.522-.294-1.437-1.079-2.27-1.92C6.218 8.098 5.433 7.19 5.14 6.66a.686.686 0 0 1-.106-.352c0-.106.04-.22.13-.318l.613-.613c.384-.392.613-.744.613-1.144 0-.31-.139-.654-.433-1.062L4.167.654C3.842.196 3.36 0 2.853 0 2.28 0 1.724.237 1.226.703.376 1.487 0 2.468 0 3.563c0 2.476 1.912 5.483 4.429 7.992z"
        fill={color}
      />
    </svg>
  );
};

export default PhoneIcon;
