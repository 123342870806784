import { useState } from 'react';
import ButtonPrimary from '../../components/ButtonPrimary/ButtonPrimary';
import '../css/_paymentMethod.scss';

const PaymentMethod = (props) => {
  const { handlePaymentCard, handlePaymentSepa, billingSelect, translate } = props;
  const [openCard, setOpenCard] = useState(false);
  const [openSepa, setOpenSepa] = useState(false);

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const handleOpenCard = () => {
    setOpenCard(!openCard);
  };

  const handleOpenSepa = () => {
    setOpenSepa(!openSepa);
  };

  return (
    <div className="paymentMethod">
      <h3 className="paymentMethod--title">{translate("payment.paymentMethod.title")}</h3>
      <div className="paymentMethod--section">
        <div className="paymentMethod--sectionSelect">
          <div>
            <p className="paymentMethod--sectionSelect__title">
              {translate("payment.paymentMethod.card")}
            </p>
            <p
              className="paymentMethod--sectionSelect__information"
              onClick={handleOpenCard}
            >
              {translate("payment.paymentMethod.moreInfo")}
            </p>
          </div>
          <ButtonPrimary
            content={translate("payment.paymentMethod.buttonSelect")}
            onClick={handlePaymentCard}
            disabled={billingSelect !== '' ? false : true}
          />
        </div>
        {openCard && (
          <div>
            <p className="text">{translate("payment.paymentMethod.moreInfo.text1")}</p>
            <p className="text bold">{translate("payment.paymentMethod.moreInfo.text2")}</p>
          </div>
        )}
      </div>
        <div className="paymentMethod--section">
          <div className="paymentMethod--sectionSelect">
            <div>
              <p className="paymentMethod--sectionSelect__title">
                {translate("payment.paymentMethod.sepa")}
              </p>
              <p
                className="paymentMethod--sectionSelect__information"
                onClick={handleOpenSepa}
              >
                {translate("payment.paymentMethod.moreInfo")}
              </p>
            </div>
            <ButtonPrimary
              content={translate("payment.paymentMethod.buttonSelect")}
              onClick={handlePaymentSepa}
              disabled={billingSelect !== '' ? false : true}
            />
          </div>
          {openSepa && (
            <div>
              <p className="text">
                {translate("payment.paymentMethod.moreInfoSepa.text1")}
              </p>
              <p className="text bold1">
                {translate("payment.paymentMethod.moreInfoSepa.text2")}{' '}
              </p>
              <p className="text bold2">{translate("payment.paymentMethod.moreInfoSepa.text3")}</p>
              <p className="text bold">
              {translate("payment.paymentMethod.moreInfoSepa.text4")}
              </p>
            </div>
          )}
        </div>
      
    </div>
  );
};

export default PaymentMethod;
