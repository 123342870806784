import FormEmployee from "../../Forms/FormEmployee.component";
import Modal from "../Modal.component";
import EmployeeFormController from "./WelcomeEmployee.controller";
import "./WelcomeEmployee.styles.scss";

const WelcomeEmployee = () => {
  const {
    error,
    handleSubmit,
    setShowModal,
    setValue,
    showModal,
    translate,
    values,
  } = EmployeeFormController();
  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      closeIcon={false}
      className="enter-done"
      ModalTitle={<h1>{translate("employees.title.newEmployee")}</h1>}
      ModalBody={
        <FormEmployee
          handleSubmit={handleSubmit}
          setValue={setValue}
          translate={translate}
          values={values}
          error={error}
        />
      }
    />
  );
};

export default WelcomeEmployee;
