import React from "react";
import "./Skeleton.styles.scss";

interface SkeletonProps {
  type: "text" | "title" | "thumbnail" | "button" | "iconButton";
  className: string;
}

const SkeletonElement: React.FC<SkeletonProps> = ({
  type,
  className,
}): JSX.Element => {
  return (
    <div className="shimmer-wrapper">
      <div className="shimmer">
        <div className={`skeleton ${type} ${className}`}></div>
      </div>
    </div>
  );
};

export default SkeletonElement;
