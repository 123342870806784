import { useEffect, useState } from "react";
import KEYS from "../../../utils/keyboardKeys";
import "../../Rebranding/atoms/Input/InputBase.styles.scss";
import InfoMessage from "../../Rebranding/atoms/InfoMessage/InfoMessage.component";

const InputGeneral = ({
  contentType,
  disabled,
  editValue,
  error,
  errorType,
  id,
  isNumber,
  label,
  name,
  onBlur,
  onBlurGreyBackground,
  placeholder,
  readOnly,
  setter,
  onFocus,
}) => {
  const [value, setValue] = useState(editValue);

  useEffect(() => {
    setValue(editValue);
  }, [editValue]);

  const handleChange = ({ target }) => {
    const { value } = target;
    setValue(value);
    if (value !== editValue) {
      setter(value.trim());
    }
  };
  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  const isAllowedKeys = (keyCode) => {
    const allowedKeys = [];
    Object.values(KEYS).forEach((value) => allowedKeys.push(value));
    const isDigit =
      !!keyCode && (keyCode.includes("Digit") || keyCode.includes("Numpad"));
    return isDigit || allowedKeys.includes(keyCode);
  };

  const className = () => {
    if (error) {
      return "inputGetLife-input-error";
    } else if (disabled || onBlurGreyBackground) {
      return "inputGetLife-onBlurGrey";
    } else {
      return "inputGetLife-input";
    }
  };

  return (
    <div className="inputGetLife-container">
      {label && (
        <label
          className={
            disabled ? "inputGetLife-labelAnswered" : "inputGetLife-label"
          }
        >
          {label}
        </label>
      )}

      <input
        className={className()}
        disabled={disabled}
        id={id}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={onFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        style={{ marginBottom: error ? "unset" : "" }}
        type={contentType}
        value={value}
        onKeyDown={(event) => {
          if (!isAllowedKeys(event.code) && isNumber) {
            event.preventDefault();
          }
          if (KEYS.KEY_TAB === event.key || KEYS.KEY_ENTER === event.key) {
            handleBlur(event);
          }
        }}
      />

      {error && (
        <InfoMessage error={error} type={errorType ? errorType : "error"} />
      )}
    </div>
  );
};

export default InputGeneral;
