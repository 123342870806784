async function apiLeadQuestionGet(leadId, brokerId, token) {
  const bearer = "Bearer " + token;
  const response = await fetch(
    process.env.REACT_APP_CUSTOMER_APP_URL + `api/lead/question/${leadId}`,
    {
      headers: {
        Authorization: bearer,
      },
    }
  );
  return await response.json();
}

export default apiLeadQuestionGet;
