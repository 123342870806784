// Vendors
import { useContext, useState } from "react";
import { intersection } from "lodash";
import { useNavigate } from "react-router-dom";
import * as FullStory from '@fullstory/browser'
// Constants
import {
  columns,
  permission,
  errors,
  buttons,
  market,
} from "../constants/Project.constants";
// Utils
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import { fetchData } from "../../../utils/fetchBrokerTables";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { I18nContext } from "../../../contexts/i18n.context";
import { GoogleContext } from "../../../contexts/GoogleTagManager.context";
import { GUARANTEE } from "../../../utils/staticData";
import { extractFromString } from "../../../utils/extractFromString";
import { getTrackingUtms } from "../../../api/request/apiLeadTrackingGet";

const ProjectCotnroller = () => {
  const navigate = useNavigate();
  // Modal related
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [data, setData] = useState();

  const {
    state: { translate },
  } = useContext(I18nContext);
  const {
    setLeadId,
    setShowNotification,
    setTokenVelogica,
    status,
    token,
    rol,
    broker,
    brokerId
  } = useContext(ContextGetlife);

  const { handleChangeDataLayer } = useContext(GoogleContext);

  const leadRepository = new HttpLeadRepository(token);

  //--------------------------------------------------------------------------------------//
  //                                  ACTIONS / HANDLERS                                  //
  //--------------------------------------------------------------------------------------//

  const recoverProject = async (leadId, data) => {
    const requestData = {
      capital: data.capital,
      insuranceType: data.insuranceType === "basic" ? false : true,
    };

    requestData.insuranceType === false
      ? (requestData.insuranceType = "basic")
      : (requestData.insuranceType = "premium");

    try {
      await leadRepository.recoverProject({ leadId, data: requestData });
      setLeadId(leadId);
      setTokenVelogica("");
      const utms = await getTrackingUtms(leadId);
      
      const event = "recoverLead";
      const eventData = {
        page: window.location.href,
        leadId: leadId,
        coverage: GUARANTEE[requestData.insuranceType],
        intent: undefined,
        calculatedPremium: data.monthlyPrice,
        paymentMethod: undefined,
        calculatedCapital: data.capital,
        gender: data.gender,
        postalCode: data.postalCode,
        brokerEmployeeId: brokerId,
        brokerId: broker.brokerageId,
        entryPage: null,
        referrerUrl: null,
        ...utms
      }

      const cookies = document.cookie;

      const VWOID = extractFromString(cookies, "_vwo_uuid_v2");
      const GAID = extractFromString(cookies, "_ga");
      const FullStoryID = extractFromString(cookies, "fs_uid");
      const AT_GD = extractFromString(cookies, "at_gd");

      const dataTracker = {
        leadUlid: leadId,
        campaign: "broker-projects",
        googleAnalyticsId: GAID,
        fullstoryId: FullStoryID,
        vwoId: VWOID,
        tid: null,
        atGd: AT_GD,
        entryPage: null,
        referrerUrl: null,
        utmCampaign: utms.utm_campaign,
        utmSource: utms.utm_source,
        utmMedium: utms.utm_medium,
        utmContent: utms.utm_content,
        utmTerm: utms.utm_term,
        utmTest: utms.utm_test,
        gclid: utms.gclid,
        fbclid: utms.fbclid
      }

      await leadRepository.saveTracking({data: dataTracker});

      handleChangeDataLayer({
        event,
        birthDate: data.birthdate,
        ...eventData
      });

      FullStory.event(event, {
        birthDate: new Date(data.birthdate),
        ...eventData
      });

      navigate("/questions");
    } catch (e) {
      setShowNotification({
        message:
          status !== "active"
            ? translate("errors.global.notUserActivated")
            : translate(`errors.projects.${e.message}`),
        status: true,
        type: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        link: (
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="mailto:broker@life5.com"
          >
            {" "}
            broker@life5.com
          </a>
        ),
      });
    }
  };

  const handlebutton = ({ ref, project }) => {
    if (ref === "recuperar") {
      recoverProject(project.leadId, project);
    } else {
      setModalData(project);
      setShowModal(true);
    }
  };

  const fetchProjects = async (params) => {
    await fetchData({ ...params, setData, token, translate });
  };

  const disabledButton = (project) => {
    return (
      project.carrier === "CNP" || project.backStatus === "prestack_pending"
    );
  };

  const getColumns = () => {
    const typeUser = intersection(permission, rol).length
      ? "admin"
      : "employee";
    const typeColumns = broker.slug === "life5" ? broker.slug : typeUser;
    return [...columns[typeColumns], ...buttons];
  };

  const tableProps = {
    columns: getColumns(),
    data,
    errors: errors,
    fetch: fetchProjects,
    handlebutton: handlebutton,
    noDataTitle: translate("projects.noData.title"),
    noDataButtonText: translate("projects.noData.buttonText"),
    placeholder: translate("projects.search.placeholder"),
    placeholderDateRange: translate("projects.search.placeholderDateRange"),
    textType: translate("projects.header.subtitle"),
    title: translate("projects.header.title"),
    translate: translate,
    type: "projects",
    disabledButton,
  };

  return {
    modalData,
    setModalData,
    setShowModal,
    showModal,
    tableProps,
    token,
    recoverProject,
  };
};

export default ProjectCotnroller;
