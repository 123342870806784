// Styles
import "./custom-table.component.scss";
// Assets
import sortIcon from "../../../assets/img/sort.svg";
// Components
import { Footer } from "./components/Footer/footer.component";
// Controller
import CustomTableController from "./controllers/custom-table.controller";
import { OmniSearch } from "../OmniSearch/omni-search.component";
import { DateRange } from "../DateRange/date-range.component";
// Constants
import { select_range_values } from "./constants/custom-table.constants";
import NoDataModal from "../../Rebranding/molecules/NoDataModal/NoDataModal.components";
import Button from "../../Rebranding/atoms/Buttons/ButtonBase/Button.component";
import Typography from "../../Rebranding/atoms/Typography/Typography.component";
import SkeletonElement from "../../Rebranding/atoms/Skeleton/SkeletonElement.component";

export const CustomTable = ({
  columns,
  componentButton,
  data,
  disabledButton,
  endDateProp,
  errors,
  fetch,
  handlebutton,
  noDataTitle,
  noDataButtonText,
  placeholder,
  placeholderDateRange,
  reset,
  setModalData,
  setShowModal,
  textType,
  title,
  translate,
  type,
}) => {
  const {
    disableDownloadButton,
    handleButton,
    handlePagination,
    handleRows,
    handleSort,
    indexPage,
    omniValue,
    pageSize,
    parseCellClass,
    setEndDate,
    setIndexPage,
    setOmniValue,
    setStartDate,
    totalItems,
    totalPages,
  } = CustomTableController({
    data,
    endDateProp,
    fetch,
    reset,
    handlebutton,
    setModalData,
    setShowModal,
    type,
  });

  const footerProps = {
    handlePagination: handlePagination,
    handleRows: handleRows,
    indexPage: indexPage,
    pageSize: pageSize,
    totalItems: totalItems,
    totalPages: totalPages,
    handleSort: handleSort,
    translate: translate
  };

  const rangeProps = {
    options: select_range_values(translate),
    placeholderDateRange,
    setEndDate: setEndDate,
    setIndexPage: setIndexPage,
    setStartDate: setStartDate,
    type: type,
    translate: translate
  };

  const searchProps = {
    omniValue: omniValue,
    placeholder: placeholder,
    setOmniValue: setOmniValue,
    type: type,
  };

  return (
    <>
      <div className="custom-table__wrapper">
        <Typography className="custom-table__title" variant="h1" text={title} />
        <Typography
          className="custom-table__count"
          variant="h3"
          text={`${totalItems} ${textType}`}
        />
        {componentButton}

        <div className="custom-header">
          <OmniSearch {...searchProps} />
          <DateRange {...rangeProps} />
        </div>
        <table className="custom-table">
          <thead className="custom-table__header">
            <tr className="custom-table__header__row">
              {columns.map((project, index) => (
                <th
                  className="custom-table__header__cell"
                  key={index}
                  onClick={
                    columns[index].sortable &&
                    (() => handleSort(columns[index].ref))
                  }
                >
                  <span className="custom-table__header__content">
                    {translate(`tables.headers.${project.ref}`)}
                    {columns[index].sortable && <img src={sortIcon} alt="" />}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="custom-table__body">
            {!data &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((key) => (
                <tr className="custom-table__body__row" key={key}>
                  {columns.map((column, indexN) => (
                    <td key={indexN}>
                      <SkeletonElement type={column.elementType} />
                    </td>
                  ))}
                </tr>
              ))}
            {data && data.length > 0 ? (
              data?.map((project, index) => (
                <tr className="custom-table__body__row" key={index}>
                  {columns.map((column, indexN) => (
                    <td
                      className={parseCellClass(column, project)}
                      key={indexN}
                    >
                      {column.type === "button" ||
                      column.type === "buttonEdit" ? (
                        <Button
                          buttonType="secondary"
                          disabled={
                            (column.type !== "buttonEdit" &&
                            disabledButton &&
                            disabledButton(project)) ||
                            disableDownloadButton(column, project)
                          }
                          onClick={() => {
                            handleButton(column.ref, project);
                          }}
                        >
                          {translate(`tables.buttons.${column.ref}`)}
                        </Button>
                      ) : (
                        <span className="custom-table__body__content">
                          {project[column.ref] ||
                            (errors && translate(`tables.error.${column.ref}`))}
                        </span>
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length}>
                  <NoDataModal
                    title={noDataTitle}
                    buttonText={noDataButtonText}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Footer {...footerProps} />
      </div>
    </>
  );
};
