import { get, post } from "../../core/HTTPService.service";
import TicketRequest from "./Model/Request/TicketRequest.model";
import TicketResponse from "./Model/Response/TicketResponse.model";

export default class HttpTicketRepository {
  private readonly API_URL_Ticket:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/ticket`;

  private readonly header: { Authorization: string } | undefined;

  constructor(token?: string) {
    this.header = token ? { Authorization: `Bearer ${token}` } : undefined;
  }

  getTicket = async (
    startDate: string,
    endDate: string
  ): Promise<TicketResponse> => {
    let data: TicketResponse;
    try {
      const response: TicketResponse = await get(
        `${this.API_URL_Ticket}s/${startDate}/${endDate}`,
        this.header
      );
      data = response;
    } catch (e) {
      throw e;
    }

    return data;
  };

  postTicket = async (data: TicketRequest): Promise<void> => {
    let dataResponse;
    try {
      const response = await post(`${this.API_URL_Ticket}`, data, this.header);
      dataResponse = response;
    } catch (e) {
      throw e;
    }
    return dataResponse;
  };
}
