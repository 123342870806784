import * as React from "react";
import { IconProps } from "./IconProps.interface";

const CalendarIcon: React.FC<IconProps> = ({
  width = "18",
  height = "16",
  color = "#fff",
  className,
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.729 16h11.872c1.816 0 2.72-.904 2.72-2.694V2.694c0-1.79-.904-2.694-2.72-2.694H2.729C.913 0 0 .895 0 2.694v10.612C0 15.105.913 16 2.729 16zm-.13-1.4c-.774 0-1.2-.408-1.2-1.216V5.188c0-.8.426-1.216 1.2-1.216h12.115c.773 0 1.208.417 1.208 1.216v8.196c0 .808-.435 1.217-1.208 1.217H2.599zM6.97 7.093h.513c.304 0 .4-.087.4-.391v-.513c0-.304-.096-.4-.4-.4H6.97c-.304 0-.408.096-.408.4V6.7c0 .304.104.39.408.39zm2.886 0h.512c.304 0 .409-.087.409-.391v-.513c0-.304-.104-.4-.409-.4h-.512c-.305 0-.409.096-.409.4V6.7c0 .304.104.39.409.39zm2.885 0h.513c.304 0 .408-.087.408-.391v-.513c0-.304-.104-.4-.408-.4h-.513c-.304 0-.4.096-.4.4V6.7c0 .304.096.39.4.39zM4.085 9.934h.504c.313 0 .408-.087.408-.391V9.03c0-.304-.095-.391-.408-.391h-.504c-.313 0-.409.087-.409.39v.514c0 .304.096.39.409.39zm2.885 0h.513c.304 0 .4-.087.4-.391V9.03c0-.304-.096-.391-.4-.391H6.97c-.304 0-.408.087-.408.39v.514c0 .304.104.39.408.39zm2.886 0h.512c.304 0 .409-.087.409-.391V9.03c0-.304-.104-.391-.409-.391h-.512c-.305 0-.409.087-.409.39v.514c0 .304.104.39.409.39zm2.885 0h.513c.304 0 .408-.087.408-.391V9.03c0-.304-.104-.391-.408-.391h-.513c-.304 0-.4.087-.4.39v.514c0 .304.096.39.4.39zm-8.656 2.85h.504c.313 0 .408-.095.408-.4v-.512c0-.304-.095-.391-.408-.391h-.504c-.313 0-.409.087-.409.39v.514c0 .304.096.4.409.4zm2.885 0h.513c.304 0 .4-.095.4-.4v-.512c0-.304-.096-.391-.4-.391H6.97c-.304 0-.408.087-.408.39v.514c0 .304.104.4.408.4zm2.886 0h.512c.304 0 .409-.095.409-.4v-.512c0-.304-.104-.391-.409-.391h-.512c-.305 0-.409.087-.409.39v.514c0 .304.104.4.409.4z"
        fill={color}
      />
    </svg>
  );
};

export default CalendarIcon;
