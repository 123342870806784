import React, { useState } from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import './customRadio.scss';

export default function CustomRadio(props) {
    const { toMap, content, subtitle, answer, disabled, handleValue } = props;

    const [value, setValue] = useState(null);

    const handleChange = (event) => {
        setValue(event.target.value);
        handleValue(event.target.value);
    };

    return (
        <>
            <div className="customRadioButton-componentContainer">
                {toMap !== undefined && (
                    <>
                        {props.content && (
                            <Grid item lg={12}>
                                <p
                                    style={{
                                        marginBottom: '26px',
                                        fontSize: '16px',
                                    }}
                                >
                                    {content}
                                </p>
                            </Grid>
                        )}
                        {subtitle && (
                            <Grid item lg={12}>
                                <p style={{ marginBottom: '37px' }}>
                                    {subtitle}
                                </p>
                            </Grid>
                        )}
                        <Grid
                            item
                            lg={12}
                            className="customRadioButton-container"
                        >
                            <FormControl>
                                <RadioGroup
                                    className={'customRadioButton-group'}
                                    id="customRadioButom-radio"
                                    name={content}
                                    value={value}
                                    onChange={handleChange}
                                    style={
                                        props.vertical === true
                                            ? {
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                              }
                                            : {
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                              }
                                    }
                                >
                                    {props.toMap.map((item) => {
                                              return (
                                                  <FormControlLabel
                                                      key={item.value}
                                                      value={item.value}
                                                      control={<Radio />}
                                                      label={item.text}
                                                      disabled={disabled}
                                                      checked={
                                                          answer === item.value
                                                              ? true
                                                              : false
                                                      }
                                                      className={answer === item.value ? 'checked' : ''}
                                                  />
                                              );
                                          })}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </>
                )}
            </div>
        </>
    );
}
