import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import HttpBrokerRepository from "../../../../api/request/Broker/broker.service";
import BrokerResponse from "../../../../api/request/Broker/Model/Response/BrokerResponse.model";
import { I18nContext } from "../../../../contexts/i18n.context";
import { Bubble } from "../../molecules/GetContact/GetContact.component";

const LandingController = () => {
  const { slug } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const [reset, setReset] = React.useState<boolean>(false);
  const [brokerId, setBrokerId] = React.useState<string>("");
  const [broker, setBroker] = React.useState<BrokerResponse>();

  const brokerRepository = new HttpBrokerRepository();

  const fetchBroker = async () => {
    try {
      const response: BrokerResponse = await brokerRepository.getBrokerBySlug(
        slug as string,
        search
      );
      setBroker(response);
      setBrokerId(response.id);
    } catch (error: any) {
      if (error._code === 303) {
        navigate(`/landing/${slug}`);
        setReset(true);
      } else {
        navigate(`/${error.message}`);
      }
    }
  };

  React.useEffect(() => {
    setBrokerId("");
    setBroker(undefined);
    (async () => await fetchBroker())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const opinions = [
    {
      name: translate("landing.ourClientsOpinion.name1"),
      text: translate("landing.ourClientsOpinion.text1"),
    },
    {
      name: translate("landing.ourClientsOpinion.name2"),
      text: translate("landing.ourClientsOpinion.text2"),
    },
    {
      name: translate("landing.ourClientsOpinion.name3"),
      text: translate("landing.ourClientsOpinion.text3"),
    },
    {
      name: translate("landing.ourClientsOpinion.name4"),
      text: translate("landing.ourClientsOpinion.text4"),
    },
    {
      name: translate("landing.ourClientsOpinion.name5"),
      text: translate("landing.ourClientsOpinion.text5"),
    },
    {
      name: translate("landing.ourClientsOpinion.name6"),
      text: translate("landing.ourClientsOpinion.text6"),
    },
  ];

  const contactsWithoutPhone: Bubble[] = [
    {
      title: translate("landing.bubble.title.firstBubble"),
      text: translate("landing.bubble.text.firstBubble"),
      contact: { text: broker?.email, type: "email" },
    },
  ];

  const contacts: Bubble[] = broker?.workPhone ? ([
    ...contactsWithoutPhone, 
    {
      title: translate("landing.bubble.title.secondBubble"),
      text: translate("landing.bubble.text.secondBubble"),
      contact: { text: broker?.workPhone, type: "phone" },
    },
  ]) : contactsWithoutPhone;


  const list = [
    {
      text: translate("landing.information.list.first"),
    },
    {
      text: translate("landing.information.list.second"),
    },
    {
      text: translate("landing.information.list.third"),
    },
    {
      text: translate("landing.information.list.fourth"),
    },
  ];

  const FAQS = [
    {
      title: translate("landing.FAQuestions.title.firstQuestion"),
      text: translate("landing.FAQuestions.text.firstText"),
    },
    {
      title: translate("landing.FAQuestions.title.secondQuestion"),
      text: translate("landing.FAQuestions.text.secondText"),
    },
    {
      title: translate("landing.FAQuestions.title.thirdQuestion"),
      text: translate("landing.FAQuestions.text.thirdText"),
    },
    {
      title: translate("landing.FAQuestions.title.fouthQuestion"),
      text: translate("landing.FAQuestions.text.fouthText"),
    },
    {
      title: translate("landing.FAQuestions.title.fiftQuestion"),
      text: translate("landing.FAQuestions.text.fiftText"),
    },
    {
      title: translate("landing.FAQuestions.title.sixthQuestion"),
      text: translate("landing.FAQuestions.text.sixthText"),
    },
  ];

  return {
    broker,
    brokerId,
    contacts,
    FAQS,
    list,
    opinions,
    translate,
  };
};

export default LandingController;
