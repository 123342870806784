// Styles
import './download-hub.component.scss';
// Components
import { DownloadItem } from '../DownloadItem/download-item.component';

export const DownloadHub = ({ data }) => {
	return (
		<div className="download-hub__wrapper">
			{data.map((item, i) => (
				<div key={`item_${i}`} className="download-hub__item">
					<DownloadItem key={i} title={item.name} total={item.amount} docId={item.invoiceDocumentId} />
				</div>
			))}
		</div>
	);
};
