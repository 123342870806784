// Styles
import './footer.component.scss';
// Constants
import { pagination_buttons_props } from '../../constants/custom-table.constants';

export const Footer = ({ handlePagination, handleRows, indexPage, pageSize, totalItems, translate }) => {
	const buildTotals = () => {
		const lowerLimit = indexPage === 1 ? indexPage : pageSize * (indexPage - 1) + 1;
		const upperLimit = pageSize * indexPage > totalItems ? totalItems : pageSize * indexPage;
		return `${lowerLimit} - ${upperLimit} de ${totalItems}`;
	};
	return (
		<div>
			<div className="custom-table__footer">
				<div className="custom-table__rowsper">
					<label hmtlfor="">{translate("tables.footer.filter")}</label>
					<select value={pageSize} onChange={handleRows}>
						<option value="10">10</option>
						<option value="15">15</option>
						<option value="20">20</option>
					</select>
				</div>
				<div className="custom-table__pagination">
					<div className="custom-table__pagination__totals">{buildTotals()}</div>
					<div className="custom-table__pagination__totals"></div>
					<div className="custom-table__pagination__buttons">
						{pagination_buttons_props.map((element, index) => (
							<div
								key={index}
								onClick={() => {
									handlePagination(element.value);
								}}>
								<img src={element.icon} alt="" />
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
