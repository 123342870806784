import ErrorResponse from "./ErrorResponse";

class HttpException extends Error {
  private readonly _code: string | number;
  private readonly _message: string;

  constructor(error: ErrorResponse) {
    super(error.error);
    this._message = error.error || error.message || "";
    this._code = error.status || "";
  }

  get message(): string {
    return this._message;
  }

  get code(): string | number {
    return this._code;
  }
}

export default HttpException;
