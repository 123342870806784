import React, { useContext, useState } from "react";
import HttpTicketRepository from "../../api/request/Tickets/ticket.service";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import { I18nContext } from "../../contexts/i18n.context";
import IncidentsMain from "./IncidentsMain";

export default function Incidents() {
  const { token, brokerId, email, setShowNotification } = useContext(ContextGetlife);
  const { state: { translate }, } = useContext(I18nContext);

  const ticketRepository = new HttpTicketRepository(token);

  const [emailState, setEmail] = useState(email);

  const [incidentType, setIncidentType] = useState(false);

  const [comments, setComments] = useState(false);

  const [isSendIncident, setIsSendIncident] = useState(false);

  const handleEmail = (e) => setEmail(e);

  const handleIncidentType = (e) => setIncidentType(e);

  const handlecomments = (e) => setComments(e);

  const submitIncident = async (e) => {
    e.preventDefault();
    try {
      await ticketRepository.postTicket({
        brokerId,
        email: emailState,
        typeCode: incidentType.value,
        text: comments,
      });
      setIsSendIncident(true);
    } catch (error) {
      setShowNotification({
        message: translate("errors.create.incident").replace( "$", emailState ),
        status: true,
        type: "error",
        autoHideDuration: 10000,
      });
    }
  };

  return (
    <IncidentsMain
      comments={comments}
      handlecomments={handlecomments}
      handleIncidentType={handleIncidentType}
      handleEmail={handleEmail}
      incidentType={incidentType}
      isSendIncident={isSendIncident}
      submitIncident={submitIncident}
      email={emailState}
      translate={translate}
    />
  );
}
