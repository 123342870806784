export interface ApiLeadTrackingGetResponse {
  [key: string]: {
    leadId: string;
    leadUlid: string;
    awarenessLanding: string;
    awarenessSource: string;
    awarenessMedium: string;
    awarenessChannel: string;
    awarenessKeyword: string;
    awarenessCampaign: string;
    awarenessContent: string;
    goalLanding: string;
    goalSource: string;
    goalMedium: string;
    goalChannel: string;
    goalKeyword: string;
    goalCampaign: string;
    goalContent: string;
    oldCampaign: string;
    googleAnalyticsId: string;
    fullstoryId: string;
    vwoId: string;
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    newCampaign: string;
    atGd: string;
    tid: string;
    utmContent: string;
    utmTerm: string;
    utmTest: string;
    fbclid: string;
    gclid: string;
  };
}

const apiLeadTrackingGet = async (
  leadId: string
): Promise<ApiLeadTrackingGetResponse> => {
  const response = fetch(
    `${process.env.REACT_APP_CUSTOMER_APP_URL}api/tracking/get/${leadId}`
  ).then((response) => response.json());

  return response;
};

export const getTrackingUtms = async (leadId: string) => {
  const getTracking: ApiLeadTrackingGetResponse = await apiLeadTrackingGet(
    leadId
  );
  const tracking = getTracking
    ? Object.values(getTracking).sort((a: any, b: any) => {
        if (a.createdAt > b.createdAt) return -1;
        if (a.createdAt < b.createdAt) return 1;
        return 0;
      })[0]
    : null;

  const utms = {
    utm_source: tracking?.utmSource ?? null,
    utm_medium: tracking?.utmMedium ?? null,
    utm_campaign: tracking?.utmCampaign ?? null,
    utm_content: tracking?.utmContent ?? null,
    utm_term: tracking?.utmTerm ?? null,
    utm_test: tracking?.utmTest ?? null,
    fbclid: tracking?.fbclid ?? null,
    gclid: tracking?.gclid ?? null,
  };
  return utms;
};

export default apiLeadTrackingGet;
