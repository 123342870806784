/* eslint-disable react-hooks/exhaustive-deps */
// Vendors
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { ContextGetlife } from "../../../../contexts/ContextGetlife";

// Constants
import { pagination_buttons_props } from "../constants/custom-table.constants";

const CustomTableController = ({
  data,
  handlebutton,
  type,
  fetch,
  endDateProp,
  reset,
}) => {
  const { setShowNotification, DOMAIN } = useContext(ContextGetlife);
  const endDateInit = endDateProp
    ? endDateProp
    : moment().local().subtract(90, "day").format("YYYY-MM-DD");
  // Table
  const [totalItems, setTotalItems] = useState(0);
  const [indexPage, setIndexPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [ordering, setOrdering] = useState(null);
  // DateRange
  const [endDate, setEndDate] = useState(endDateInit);
  const [startDate, setStartDate] = useState(
    moment().local().format("YYYY-MM-DD")
  );
  // OmniSearch
  const [omniValue, setOmniValue] = useState("");
  // Filter
  const [direction, setDirection] = useState("DESC");
  const [totalPages, setTotalPages] = useState(
    Math.ceil(totalItems / pageSize)
  );

  //--------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                       HANDLERS                                       //
  //                                                                                      //
  //--------------------------------------------------------------------------------------//

  const handleButton = (ref, project) => {
    handlebutton({ ref, project });
  };

  const handleSort = (field) => {
    if (direction === "ASC") {
      setDirection("DESC");
    } else {
      setDirection("ASC");
    }
    setOrdering({ field: field, direction: direction });
  };

  const handlePagination = (value) => {
    let newIndex = 1;
    if (value === pagination_buttons_props[0].value) {
      newIndex = 1;
    } else if (value === pagination_buttons_props[1].value && indexPage > 1) {
      newIndex = indexPage - 1;
    } else if (
      value === pagination_buttons_props[2].value &&
      indexPage < totalPages
    ) {
      newIndex = indexPage + 1;
    } else if (value === pagination_buttons_props[3].value) {
      newIndex = totalPages;
    } else {
      newIndex = indexPage;
    }
    setIndexPage(newIndex);
  };

  const handleRows = (e) => {
    setPageSize(e.target.value);
  };

  const parseCellClass = (column, project) => {
    let baseClassName = "custom-table__body__cell";
    if (column.ref === "status") {
      baseClassName += ` status-cell`;
      if (project.overrideClasses?.status) {
        baseClassName += ` ${project.overrideClasses.status}`;
      }
    } else if (column.ref === "email") {
      if (!project.email) {
        baseClassName += ` orangy`;
      }
    } else if (column.ref === "phone") {
      if (!project.phone) {
        baseClassName += ` orangy`;
      }
    }
    return baseClassName;
  };

  const disableDownloadButton = (column, project) => {
    if (column.ref === "descargar") {
      if (project.backStatus !== "complete" && DOMAIN === "fr") {
        return true;
      }
    }

    return false;
  };

  //--------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                        UTILS                                         //
  //                                                                                      //
  //--------------------------------------------------------------------------------------//

  const params = {
    endDate,
    indexPage,
    omniValue,
    ordering,
    pageSize,
    setIndexPage,
    setShowNotification,
    setTotalItems,
    startDate,
    type,
  };
  //--------------------------------------------------------------------------------------//
  //                                                                                      //
  //                              LISTENING CALLS // SETTERS                              //
  //                                                                                      //
  //--------------------------------------------------------------------------------------//

  useEffect(() => {
    fetch({ ...params, reset: true });
  }, [endDate, pageSize, startDate, ordering, reset]);

  useEffect(() => {
    if (!!data) {
      const delayDebounceFn = setTimeout(() => {
        fetch({ ...params, reset: true });
      }, 300);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [omniValue]);

  useEffect(() => {
    if (!!data) {
      fetch({ ...params, reset: false });
    }
  }, [indexPage]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / pageSize));
  }, [totalItems, pageSize]);

  return {
    disableDownloadButton,
    handleButton,
    handlePagination,
    handleRows,
    handleSort,
    indexPage,
    omniValue,
    pageSize,
    parseCellClass,
    setEndDate,
    setIndexPage,
    setOmniValue,
    setStartDate,
    totalItems,
    setTotalItems,
    totalPages,
  };
};

export default CustomTableController;
