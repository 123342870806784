import { useState, useEffect, useContext } from "react";
import DashboardMain from "./DashboardMain";
import * as FullStory from "@fullstory/browser";
import apiLeadPrice from "./../../api/request/apiLeadPrice";
import apiLeadSendDocument from "../../api/request/apiLeadSendDocument";
import {
  GUARANTEE,
  initialValuesFormCalcES,
  initialValuesFormCalcFR,
  requiredFormCalcDash,
  requiredFormCalcDashFR,
} from "../../utils/staticData";
import validateForm from "../../utils/formValidator";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import HttpLeadRepository from "../../api/request/Lead/lead.service";
import { I18nContext } from "../../contexts/i18n.context";
import { GoogleContext } from "../../contexts/GoogleTagManager.context";
import useCapitalLimits from "../../hooks/useCapitalLimits.hook";
import apiLeadGetProfessions from "../../api/request/apiLeadGetProfessions";
import apiLeadValidatePhoneFr from "../../api/request/apiLeadValidatePhoneFr";
import apiLeadValidateZipCodeFr from "../../api/request/apiLeadValidateZipCodeFr";
import apiGetDownloadableFiles from "../../api/request/apiGetDownloadableFiles";
import { extractFromString } from "../../utils/extractFromString";

export const emptyObjects = (obj) => Object.keys(obj).length;

export const validateBirthdate = (birthDate) => {
  const birth = birthDate.split("-");
  return birth
    .map((data) => (parseInt(data) < 10 ? "0" + parseInt(data) : data))
    .join("-");
};

export const fetchCapitalLimits = async ({
  setCapitalLimits,
  tarifierRepository,
  birthDate,
  setCapital,
  setTraits,
  setShowNotification,
  translate,
}) => {
  try {
    const newBirthDate = validateBirthdate(birthDate);
    const response = await tarifierRepository.getCapitalLimits(newBirthDate);
    const { minCapital, maxCapital, availableTraits } = response;
    setCapitalLimits({
      capitalRangeMax: maxCapital,
      capitalRangeMin: minCapital,
      capitalRangeStep: maxCapital === 500000 ? 25000 : 10000,
    });
    setCapital(maxCapital / 2);
    setTraits({
      death: availableTraits.DEATH,
      disability: availableTraits.DISABILITY,
    });
    if (
      availableTraits.DEATH === false &&
      availableTraits.DISABILITY === false
    ) {
      setShowNotification({
        message: translate("errors.dashboard.birthdate"),
        status: true,
        type: "info",
        autoHideDuration: 5000,
      });
    }
  } catch (error) {
    setShowNotification({
      message: translate(`errors.dashboard.${error.message}`),
      status: true,
      type: "error",
    });
  }
};

export default function Dashboard() {
  const {
    broker,
    brokerId,
    leadId,
    setLeadId,
    setLoading,
    setShowNotification,
    setTokenVelogica,
    token
  } = useContext(ContextGetlife);

  const { handleChangeDataLayer } = useContext(GoogleContext);

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const {
    state: { translate },
  } = useContext(I18nContext);

  const leadRepository = new HttpLeadRepository(token);

  const initialValuesFormCalc =
    (DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it")
      ? initialValuesFormCalcES
      : initialValuesFormCalcFR;

  const [birthDate, setBirthDate] = useState(initialValuesFormCalc.birthday);

  const [capital, setCapital] = useState(0);

  const [emailToSend, setEmailToSend] = useState("");

  const [error, setError] = useState([]);

  const [insurancePrice, setInsurancePrice] = useState(false);

  const [notOwner, setNotOwner] = useState(false);

  const [insuranceType, setInsuranceType] = useState();

  const [workingProfesionList, setWorkingProfesionList] = useState([]);

  const [shareText] = useState(translate("dashboard.button.share"));

  const [buttonCalculate, setButtonCalculate] = useState("calculate");

  const [showAlerts, setShowAlerts] = useState({
    maxCapital: false,
    disability: false,
    recalcMaxCapital: false,
    recalcDisability: false,
  });

  const [showModalSend, setShowModalSend] = useState(false);

  const [showResults, setShowResults] = useState(false);
  const { limits, traits, setBirthdate } = useCapitalLimits({
    setCapital,
    setShowNotification,
    translate,
    token,
  });

  const [downloadablePdfs, setDownloadablePdfs] = useState([]);

  const handleModalSend = () => {
    setShowModalSend(!showModalSend);
  };

  useEffect(() => {
    if (birthDate) {
      setBirthdate(validateBirthdate(birthDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthDate]);

  useEffect(() => {
    if (
      process.env.REACT_APP_DOMAIN_LOCALE === "fr" &&
      workingProfesionList.length === 0
    ) {
      apiLeadGetProfessions(token).then((response) => {
        const listFormat = [];
        response.value.map((item) => {
          item.label = item.value;
          item.value = item.key;
          listFormat.push(item);
        });

        setWorkingProfesionList(listFormat);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowResults(false);
    setShowAlerts({
      maxCapital: limits.maxCapital === 50000 && capital !== 0,
      disability:
        !traits.DISABILITY && limits.maxCapital !== 0 && capital !== 0,
      recalcMaxCapital:
        limits.maxCapital === 50000 && buttonCalculate === "recalculate",
      recalcDisability:
        !traits.DISABILITY &&
        limits.maxCapital !== 0 &&
        buttonCalculate === "recalculate",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limits, traits]);

  useEffect(() => {
    if (capital || insuranceType) {
      setShowResults(false);
    }
  }, [capital, insuranceType]);

  useEffect(() => {
    setLeadId("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInsurancePrice = (e) => setInsurancePrice(e);

  const getTemplates = (insuranceType) => {
    let templates;
    if (insuranceType === "DEVIS") {
      templates = ["devis"];
    } else {
      templates = ["offer-w-base"];
      if (insuranceType === "premium") {
        templates = ["offer-w-base", "ipa-any-cause-exclusions"];
      }
    }

    return templates;
  };

  const sendEmailDocument = async (email, text, insuranceType) => {
    const templates = getTemplates(insuranceType);
    const type = "general-a-exclusions";
    const resource = null;
    try {
      await apiLeadSendDocument(
        token,
        leadId,
        type,
        text,
        email,
        resource,
        templates
      );
      setShowModalSend(false);
      setShowNotification({
        message: translate("notification.email.correct"),
        status: true,
        type: "success",
      });
    } catch (e) {
      setShowNotification({
        message: translate("notification.email.error"),
        status: true,
        type: "error",
      });
    }
  };

  const cookies = document.cookie;

  const VWOID = extractFromString(cookies, "_vwo_uuid_v2");
  const GAID = extractFromString(cookies, "_ga");
  const FullStoryID = extractFromString(cookies, "fs_uid");
  const AT_GD = extractFromString(cookies, "at_gd");

  const fetchSubmitOrganicES = async (values) => {
    const {
      capital,
      email,
      gender,
      insuranceType,
      intention,
      phone,
      referenceName,
      zipCode,
    } = values;
    try {
      const response = await leadRepository.postOrganic({
        birthdate: birthDate,
        brokerId,
        capital: parseInt(capital),
        email: email || "",
        gender: gender["value"],
        insuranceType: insuranceType["value"],
        intention: intention["value"],
        phone: phone,
        referenceName: referenceName,
        token,
        zipCode: zipCode,
      });
      const { leadId, notOwner } = response;
      const _downloadablePdfs = await apiGetDownloadableFiles(leadId, values.insuranceType.value, token)
      setDownloadablePdfs(_downloadablePdfs)
      setNotOwner(notOwner);
      setLeadId(leadId);
      setShowResults(leadId);
      setEmailToSend(response.email);
      const price = await apiLeadPrice(leadId, token);
      setInsurancePrice(price);
      setLoading(false);
      setButtonCalculate("recalculate");
      document.getElementById("result").scrollIntoView({ behavior: "smooth" });

      const dataTracker = {
        leadUlid: leadId,
        campaign: "broker-dashboard",
        googleAnalyticsId: GAID,
        fullstoryId: FullStoryID,
        vwoId: VWOID,
        utmCampaign: brokerId,
        utmSource: "brokerapp",
        utmMedium: broker.brokerageId,
        utmContent: null,
        utmTerm: null,
        utmTest: null,
        gclid: null,
        fbclid: null,
        tid: null,
        atGd: AT_GD,
        entryPage: null,
        referrerUrl: null
      }

      await leadRepository.saveTracking({data: dataTracker});

      const eventData = {
        calculatedCapital: parseInt(capital),
        calculatedPremium: parseFloat(price.monthlyPrice),
        coverage: GUARANTEE[insuranceType.value],
        email: email,
        gender: gender.value,
        intent: intention.value,
        leadId: leadId,
        page: window.location.href,
        phone: phone,
        postalCode: zipCode,
        brokerEmployeeId: brokerId,
        brokerId: broker.brokerageId,
        utm_source: "brokerapp",
        utm_campaign: brokerId,
        utm_medium: broker.brokerageId,
        platform: "Broker",
        entryPage: null,
        referrerUrl: null
      }

      if (response.isFirstIncoming) {
        const event = "leadCreated";

        handleChangeDataLayer({
          event,
          birthDate: birthDate,
          ...eventData
        });
        FullStory.event(event, {
          birthDate: new Date(birthDate),
          ...eventData
        });
      }
      const event = "calculatorQuoteGenerated";

      handleChangeDataLayer({
        event,
        birthDate: birthDate,
        ...eventData
      });
      FullStory.event(event, {
        birthDate: new Date(birthDate),
        ...eventData
      });

    } catch (e) {
      setLoading(false);
      setShowNotification({
        message: translate(`errors.dashboard.${e.message}`),
        status: true,
        type: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        autoHideDuration: 10000,
      });
    }
  };

  const fetchSubmitOrganicFR = async (values) => {
    const {
      capital,
      email,
      gender,
      phone,
      referenceName,
      zipCode,
      smoker,
      workingProfesion,
      workHeights,
      workDistance,
      workTools,
    } = values;
    try {
      const response = await leadRepository.postOrganic({
        birthdate: birthDate,
        brokerId,
        capital: parseInt(capital),
        email: email || "",
        gender: gender["value"],
        phone: phone || null,
        referenceName: referenceName,
        token,
        zipCode: zipCode,
        nicotine: smoker["value"],
        scorProfessionId: workingProfesion["value"],
        distanceUpper25000KmPerYear: workDistance["value"],
        heightMoreThan3m: workHeights["value"],
        loadMoreThan5Kg: workTools["value"],
      });
      const { leadId, notOwner } = response;
      const _downloadablePdfs = await apiGetDownloadableFiles(leadId, "DEVIS")
      setDownloadablePdfs(_downloadablePdfs)
      setTokenVelogica("");
      setNotOwner(notOwner);
      setLeadId(leadId);
      setShowResults(leadId);
      setEmailToSend(response.email);
      const price = await apiLeadPrice(leadId, token);
      setInsurancePrice(price);
      setLoading(false);
      setButtonCalculate("recalculate");
      document.getElementById("result").scrollIntoView({ behavior: "smooth" });

      const dataTracker = {
        leadUlid: leadId,
        campaign: "broker-dashboard",
        googleAnalyticsId: GAID,
        fullstoryId: FullStoryID,
        vwoId: VWOID,
        utmCampaign: brokerId,
        utmSource: "brokerapp",
        utmMedium: broker.brokerageId,
        utmContent: null,
        utmTerm: null,
        utmTest: null,
        gclid: null,
        fbclid: null,
        tid: null,
        atGd: AT_GD,
        entryPage: null,
        referrerUrl: null
      }

      await leadRepository.saveTracking({data: dataTracker});

      const eventData = {
        calculatedCapital: parseInt(capital),
        calculatedPremium: parseFloat(price.monthlyPrice),
        coverage: GUARANTEE[price.insuranceType],
        email: email,
        gender: gender.value,
        intent: "loan",
        leadId: leadId,
        page: window.location.href,
        phone: phone,
        postalCode: zipCode,
        brokerEmployeeId: brokerId,
        brokerId: broker.brokerageId,
        utm_source: "brokerapp",
        utm_campaign: brokerId,
        utm_medium: broker.brokerageId,
        platform: "Broker",
        entryPage: null,
        referrerUrl: null
      }

      if (response.isFirstIncoming) {
        const event = "leadCreated";

        handleChangeDataLayer({
          event,
          birthDate: birthDate,
          ...eventData
        });
        FullStory.event(event, {
          birthDate: new Date(birthDate),
          ...eventData
        });
      }
      const event = "calculatorQuoteGenerated";

      handleChangeDataLayer({
        event,
        birthDate: birthDate,
        ...eventData
      });
      FullStory.event(event, {
        birthDate: new Date(birthDate),
        ...eventData
      });

    } catch (e) {
      setLoading(false);
      setShowNotification({
        message: translate(`errors.dashboard.${e.message}`),
        status: true,
        type: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        autoHideDuration: 10000,
      });
    }
  };

  const validateSubmitForm = (values) => {
    let errorData = [];

    try {
      validateForm({
        values,
        requiredFields: requiredFormCalcDash,
        dashBoard: true,
        translate,
      });
    } catch (e) {
      setLoading(false);
      errorData = JSON.parse(e.message);
    }
    return errorData;
  };

  const validateSubmitFormFR = (values) => {
    let errorData = [];

    try {
      validateForm({
        values,
        requiredFields: requiredFormCalcDashFR,
        dashBoard: true,
        translate,
      });
    } catch (e) {
      setLoading(false);
      errorData = JSON.parse(e.message);
    }
    return errorData;
  };

  const handleSubmitOrganic = async (values) => {
    setLoading(true);
    if ((DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it")) {
      const errorData = validateSubmitForm(values);
      if (!traits.DEATH) {
        setShowNotification({
          message: translate("errors.limits.ageRange"),
          status: true,
          type: "error",
          autoHideDuration: 5000,
        });
      }
      setError(errorData);
      if (!errorData.length) {
        await fetchSubmitOrganicES(values);
      }
    } else {
      const errorData = validateSubmitFormFR(values);
      setError(errorData);
      const PHONE_VALIDATE =
        values.phone && (await apiLeadValidatePhoneFr(values.phone));
      const ZIPCODE_VALIDATE = await apiLeadValidateZipCodeFr(values.zipCode);

      if (
        (values.phone && !PHONE_VALIDATE.isValidPhoneFR) ||
        !ZIPCODE_VALIDATE.isValidPostalCodeFR ||
        errorData.length
      ) {
        setLoading(false);
        if (!errorData.lenght) {
          if (values.phone && !PHONE_VALIDATE.isValidPhoneFR) {
            setShowNotification({
              message: translate("errors.dashboard.phoneNotValid"),
              status: true,
              type: "error",
              autoHideDuration: 5000,
            });
          }

          if (!ZIPCODE_VALIDATE.isValidPostalCodeFR) {
            setShowNotification({
              message: translate("errors.dashboard.zipCodeNotValid"),
              status: true,
              type: "error",
              autoHideDuration: 5000,
            });
          }
        }
      } else {
        await fetchSubmitOrganicFR(values);
      }
    }
  };

  return (
    <DashboardMain
      broker={broker}
      buttonCalculate={buttonCalculate}
      capital={capital}
      capitalLimits={limits}
      emailToSend={emailToSend}
      error={error}
      handleInsurancePrice={handleInsurancePrice}
      handleModalSend={handleModalSend}
      handleSubmitOrganic={handleSubmitOrganic}
      initialValues={initialValuesFormCalc}
      insurancePrice={insurancePrice}
      notOwner={notOwner}
      sendEmailDocument={sendEmailDocument}
      setBirthDate={setBirthDate}
      setCapital={setCapital}
      setInsuranceType={setInsuranceType}
      setNotOwner={setNotOwner}
      setShowAlerts={setShowAlerts}
      setButtonCalculate={setButtonCalculate}
      setShowModalSend={setShowModalSend}
      setShowResults={setShowResults}
      shareText={shareText}
      showAlerts={showAlerts}
      showModalSend={showModalSend}
      showResults={showResults}
      token={token}
      traits={traits}
      workingProfesionList={workingProfesionList}
      downloadablePdfs={downloadablePdfs}
    />
  );
}
