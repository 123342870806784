import React, {useContext} from 'react';
import { SelectColumnFilter, Table } from '../components/table/Table';
import { DateRangeColumnFilter } from '../components/table/DateRangeColumnFilter';
import SelectGetlife from '../components/select/SelectGetlife';
import moment from 'moment';
import Incident from '../../assets/img/incident.svg';
import Phone2 from '../../assets/img/phone2.svg';
import Email from '../../assets/img/email.svg';
import './css/ticketListMain.scss';
import numberFormat from "../../utils/numberFormat";
import WhiteButton from "../components/buttons/WhiteButton";
import { I18nContext } from '../../contexts/i18n.context';

export default function TicketListMain(props) {

    const {
        state: { translate },
      } = useContext(I18nContext);

    const {
        data,
        handleRangue,
        statuses,
    } = props;

    const rangues = [
        translate("tables.range.today"),
        translate("tables.range.7days"),
        translate("tables.range.15days"),
        translate("tables.range.30days"),
        translate("tables.range.3months"),
        translate("tables.range.6months"),
        translate("tables.range.lastYear"),
        translate("tables.range.all")
    ];

    const optionsRangues = [];
    rangues.forEach((rangue, key) =>
        optionsRangues.push({ value: String(key + 1), label: String(rangue) })
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'Email',
                accessor: 'email', // accessor is the "key" in the data
            },
            {
                Header: 'Teléfono',
                accessor: 'phone',
            },
            {
                Header: 'Fecha creación',
                // accessor: 'buyDate',
                accessor: (d) => {
                    let date = moment(d.createdAt).local();
                    if (date.isValid()) {
                        return date.format('DD-MM-YYYY');
                    } else {
                        return '';
                    }
                },
                Filter: DateRangeColumnFilter,
                filter: 'dateBetween',
            },
            {
                Header: 'Estado',
                accessor: 'status',
                Filter: SelectColumnFilter,
                filter: 'includes',
                Cell: ({ row }) => {
                    const status = row.original.status;
                    if (status === 'Abierto') {
                        const styles = {
                            color: 'red',
                            fontWeight: 'bold',
                        };
                        return <div style={styles}>{status}</div>;
                    } else if (status === 'Cerrado') {
                        const styles = {
                            color: 'green',
                            fontWeight: 'bold',
                        };
                        return <div style={styles}>{status}</div>;
                    } else {
                        return <div>{status}</div>;
                    }
                },
            },
            {
                Header: 'Prima anual',
                accessor: (d) => {
                    return numberFormat(d.price, 'price');
                },
            },

            {
                Header: 'Tipo',
                accessor: 'type',
            },
            {
                Header: 'Solicitar ayuda',
                accessor: 'help',
                Filter: false,
                Cell: () => {
                    return (
                        <a
                            href="https://life5.es/contacto/"
                            className="table--anchor"
                        >
                            <img
                                src={Incident}
                                alt="icono de un teléfono, si se pulsa llama."
                            />
                        </a>
                    );
                },
            },
            {
                Header: 'Llamar',
                accessor: 'llamar',
                Filter: false,
                Cell: ({ row }) => {
                    if (!row.original.phone) {
                        return '';
                    }
                    const phone = row.original.phone.toString();
                    const href = `tel:${phone}`;
                    return (
                        <a href={href} className="table--anchor">
                            <img
                                src={Phone2}
                                alt="icono de un teléfono, si se pulsa llama."
                            />
                        </a>
                    );
                },
            },
            {
                Header: 'Email',
                accessor: 'sendEmail',
                Filter: false,
                Cell: ({ row }) => {
                    const email = row.original.email;
                    const href = 'mailto:' + email;
                    return (
                        <a className="table--anchor" href={href}>
                            <img
                                src={Email}
                                alt="icono de email, si se pulsa salta un pop-up para escribir el email."
                            />
                        </a>
                    );
                },
            },
        ],
        []
    );

    return (
        <div className="ticketsMain">
            {/* <div className="red">Falta de completar</div> */}
            <h1 className="ticketsMain--title">{translate("tables.incidents.title")}</h1>
            <p className="ticketsMain--text">
                {translate("tables.incidents.subtitle1")}
            </p>
            <p className="ticketsMain--text">
                {translate("tables.incidents.subtitle2")}
            </p>
            <div className="ticketsMain--nav">
                <div className="ticketsMain--navContainer">
                    <div className="ticketsMain--nav__select">
                        <SelectGetlife
                            toMap={optionsRangues}
                            handleValues={handleRangue}
                            placeholder={translate("tables.incidents.placeholderDateRange")}
                        />
                    </div>
                    <WhiteButton content={translate("tables.incidents.buttonNew")} />
                </div>
                <div className="ticketsMain--navInfo">
                    <p className="ticketsMain--nav__resolve projOrder1">
                        {translate("tables.incidents.solved")} <b>{statuses['Cerrado']}</b>
                    </p>
                    <p className="ticketsMain--nav__pendent projOrder2">
                    {translate("tables.incidents.pending")} <b>{statuses['Abierto']}</b>
                    </p>
                </div>
            </div>
            {data !== false && <Table data={data} columns={columns} />}
        </div>
    );
}
