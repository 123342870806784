import React, { useState } from 'react';
import './css/textArea.scss';

export default function TextArea(props) {
    const { label, handleValues, onBlurGreyBackground, placeholder, answer } =
        props;

    const [actualStyle, setActualStyle] = useState('');

    const handleChange = (e) => {
        handleValues(e.target.value);
    };
    return (
        <div className="textArea-textAreaContainer">
            <p>{label}</p>

            <textarea
                className={actualStyle}
                value={answer}
                placeholder={placeholder}
                onBlur={() =>
                    onBlurGreyBackground
                        ? setActualStyle('textArea-onBlurGrey')
                        : null
                }
                onChange={handleChange}
            ></textarea>
        </div>
    );
}
