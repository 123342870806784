import { useState } from "react";
import { useCloseByKey } from "../../utils/closeByKey.ts";
import KEYS from "../../utils/keyboardKeys";
import ButtonPrimary from "../components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../components/buttons/ButtonSecondary";
import InputGeneral from "../components/Input/Input";
import Modal from "../Rebranding/organisms/Modal/Modal.component";

const InvitationEmployee = ({
  handleSubmit,
  id,
  setShowInvitation,
  showInvitation,
  translate
}) => {
  const [email, setEmail] = useState("");
  useCloseByKey({
    key: KEYS.KEY_ENTER,
    implementedFunction: () => {
      if (!!email) {
        handleSubmit(email);
      }
    },
    setClose: setShowInvitation,
    value: email,
  });

  useCloseByKey({ key: KEYS.KEY_ESC, setClose: setShowInvitation });
  return (
    <Modal
      id={id}
      showModal={showInvitation}
      setShowModal={setShowInvitation}
      ModalTitle={
        <h1 className="employees-modal-title">{translate("inviteEmployee.modal.title")}</h1>
      }
      ModalBody={
        <div className="employees-invitation">
          <div className="employees-input">
            <InputGeneral
              editValue={email}
              id="input-invitation"
              label={translate("inviteEmployee.modal.email")}
              name="email"
              setter={(newValue) => setEmail(newValue)}
            />
          </div>
          <p>
            {translate("inviteEmployee.modal.subtitle")}
          </p>
        </div>
      }
      ModalActions={
        <div className="employees-invitation-buttons">
          <ButtonSecondary
            content={translate("inviteEmployee.modal.buttonCancel")}
            className="upsell-personalization-add-button"
            handleChange={() => setShowInvitation(false)}
          />
          <ButtonPrimary
            content={translate("inviteEmployee.modal.buttonInvite")}
            onClick={() => handleSubmit(email)}
          />
        </div>
      }
    />
  );
};

export default InvitationEmployee;
