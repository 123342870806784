import React from "react";
import { errorMessage } from "../../../../../utils/error";
import Button from "../../../atoms/Buttons/ButtonBase/Button.component";
import CrossIcon from "../../../atoms/Icons/CrossIcon";
import TickIcon from "../../../atoms/Icons/TickIcon";
import InfoMessage from "../../../atoms/InfoMessage/InfoMessage.component";
import InputGeneral from "../../../molecules/InputGeneral/InputGeneral.component";
import { ValidateCouponT } from "../../../pages/Checkpoint/CheckPoint.controller";
import Modal from "../Modal.component";
import CouponModalController, {
  CouponModalControllerI,
} from "./CouponModal.controller";

import "./CouponModal.styles.scss";

interface CouponModalI extends CouponModalControllerI {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  translate: (key: string) => string;
  validateCoupon?: ValidateCouponT;
}

const CouponModal: React.FC<CouponModalI> = ({
  couponCode,
  fetchPromo,
  yearlyPrice,
  showModal,
  setShowModal,
  translate,
  validateCoupon,
}): JSX.Element => {
  const { disabled, error, handleSubmit, setDisabled, setValue, values } =
    CouponModalController({ couponCode, fetchPromo, yearlyPrice });
  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      closeIcon
      ModalTitle={<h1>{translate("coupon.title")}</h1>}
      ModalBody={
        <div className="couponModal__body">
          <InputGeneral
            editValue={values["couponCode"]}
            label={translate("coupon.form.couponCode")}
            setter={(newValue: string) => {
              setDisabled(!!newValue);
              setValue("couponCode", newValue);
            }}
            name="couponCode"
            placeholder={"GETLIFEPROMO"}
            className="couponModal__body--input"
            error={errorMessage(error, "bankName")}
          />
          {!!validateCoupon && validateCoupon !== "error" && (
            <InfoMessage
              icon={
                validateCoupon === "validate" ? <TickIcon /> : <CrossIcon />
              }
              className="couponModal__body--message"
              message={translate(`coupon.message.${validateCoupon}`)}
              type={validateCoupon === "validate" ? "info" : "error"}
            />
          )}
        </div>
      }
      ModalActions={
        <div className="beneficiaries--modal-buttons">
          <Button onClick={() => handleSubmit()} disabled={!disabled}>
            {translate(`coupon.buttons.addCoupon`)}
          </Button>
        </div>
      }
    />
  );
};

export default CouponModal;
