// Vendors
import { useContext, useEffect, useState } from "react";
import moment from "moment";

// Utils
import numberFormat from "../../utils/numberFormat";
// Components
import SelectGetlife from "../components/select/SelectGetlife";
import { DownloadHub } from "../components/DownloadHub/download-hub.component";
// Constants
import constants from "./constants/Earnings.constants";
// Styles
import "./css/earningsMain.scss";
import { ContextGetlife } from "../../contexts/ContextGetlife";
// API
import HttpBrokerRepository from "../../api/request/Broker/broker.service";
import HttpListRepository from "../../api/request/Lists/list.service";

export default function Earnings() {
  // TODO: Refactor incoming.
  const { brokerId, token } = useContext(ContextGetlife);
  const brokerRepository = new HttpBrokerRepository(brokerId, token);
  const listRepository = new HttpListRepository(token);
  //Download
  const [docList, setDocList] = useState([]);
  // Range
  const [totalAmount, setTotalAmount] = useState(false);
  const [totalCount, setTotalCount] = useState(false);
  const [dateRange, setDateRange] = useState(constants.select_range_values[2]);

  const handleRangue = (e) => {
    setDateRange(e);
  };

  useEffect(() => {
    const dateFormat = "YYYY-MM-DD";
    const fillData = () => {
      brokerRepository.getEarnings(startDate, endDate).then((data) => {
        setTotalCount(data.policiesCount);
        setTotalAmount(data.totalAmount);
      });

      listRepository.getInvoiceList().then((data) => {
        try {
          setDocList(data);
        } catch (error) {}
      });
    };
    const parseStartDate = (daysValue) => {
      if (daysValue < 0) {
        return "2020-01-01";
      }
      return moment()
        .local()
        .subtract(dateRange.value, "day")
        .format(dateFormat);
    };
    const endDate = moment().local().format(dateFormat);
    const startDate = parseStartDate(dateRange.value);
    fillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, brokerId, token]);

  return (
    <div className="earningsMain-mainContainer">
      <div className="earningsMain-container">
        <header className="earningsMain-headerTextContainer">
          <h1>Ingresos</h1>
          <p>
            Aquí podrás descargar los recibos mensualmente y consultar tus
            ingresos y comisiones.
          </p>
        </header>

        <article className="earningsMain-earningsBoxContainer">
          <div className="earningsMain-popUpMainContainer">
            <SelectGetlife
              placeholder={"Rango de fechas"}
              toMap={constants.select_range_values}
              handleValues={handleRangue}
              default={dateRange.value}
            />
          </div>
          <div className="earningsMain-totalEarningsBody">
            <div className="earningsMain-totalEarningsContainer">
              <div className="earningsMain-totalEarnings">
                <p>Total de ingresos</p>
              </div>
              <div className="earningsMain-footer">
                <p>{dateRange.label}</p>
                <div>
                  <h1>{numberFormat(totalAmount, "price")}</h1>
                </div>
              </div>
            </div>

            <div className="earningsMain-totalEarningsContainer">
              <div className="earningsMain-totalEarnings">
                <p>Total pólizas emitidas</p>
              </div>
              <div className="earningsMain-footerPolicies">
                <p>{dateRange.label}</p>
                <div>
                  <h1>{totalCount}</h1>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="earningsMain-booksMainContainer">
          <div className="earningsMain-totalEarnings">
            {!docList.length ? (
              <p>No hay liquidaciones disponibles para descargar</p>
            ) : (
              <p>Liquidaciones disponibles para descargar</p>
            )}
          </div>

          <div className="earningsMain-downloadContainer">
            <DownloadHub data={docList} />
          </div>
        </article>
      </div>
    </div>
  );
}
