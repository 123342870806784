// Assets
import firstSvg from "../../../../assets/img/pagination/first.svg";
import lastSvg from "../../../../assets/img/pagination/last.svg";
import nextSvg from "../../../../assets/img/pagination/next.svg";
import prevSvg from "../../../../assets/img/pagination/prev.svg";

export const pagination_buttons_props = [
  {
    icon: firstSvg,
    value: "first",
  },
  {
    icon: prevSvg,
    value: "prev",
  },
  {
    icon: nextSvg,
    value: "next",
  },
  {
    icon: lastSvg,
    value: "last",
  },
];

export const select_range_values = (translate) => [
  { label: translate("tables.range.today"), value: 0 },
  { label: translate("tables.range.7days"), value: 7 },
  { label: translate("tables.range.15days"), value: 15 },
  { label: translate("tables.range.30days"), value: 30 },
  { label: translate("tables.range.3months"), value: 90 },
  { label: translate("tables.range.6months"), value: 180 },
  { label: translate("tables.range.lastYear"), value: 365 },
  { label: translate("tables.range.all"), value: -1 },
];
