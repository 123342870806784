import { useState } from "react";
import { Answers } from "../../../../../api/request/Lead/Model/Response/NomenclatorResponse.model";
import { errorMessage } from "../../../../../utils/error";
import SelectGeneral from "../../../../components/SelectGeneral/SelectGeneral";
import MinusIcon from "../../../atoms/Icons/MinusIcon.component";
import PlusIcon from "../../../atoms/Icons/PlusIcon.component";
import InputGeneral from "../../../molecules/InputGeneral/InputGeneral.component";
import { FormNationalityProps } from "../FromInterface.model";
import "./FormNationality.styles.scss";

const FormNationality = ({
  error,
  setValue,
  translate,
  values,
  countries,
  disabled,
  addNationality,
}: FormNationalityProps): JSX.Element => {
  const [extraNationality, setExtraNationality] = useState<boolean>(false);
  const classNameSelect: string = addNationality
    ? "form-nationality-select"
    : "form-nationality-simple";
  return (
    <>
      <div className="form-nationality-container">
        <SelectGeneral
          className={classNameSelect}
          isMultiple={false}
          errorType="error"
          label={translate("global.nationality")}
          editValue={values["nationality"]}
          error={errorMessage(error, "nationality")}
          handleValues={(newValue: Answers) =>
            setValue("nationality", newValue)
          }
          placeholder={translate("global.select.list")}
          toMap={countries}
          disabled={disabled}
          width={{ width: "41px" }}
        />
        {addNationality && (
          <PlusIcon
            className="form-nationality-icon"
            color="#3A36F2"
            onClick={() => !disabled && setExtraNationality(true)}
          />
        )}
      </div>
      <SelectGeneral
        className=""
        isMultiple={false}
        errorType="error"
        label={translate("global.residence")}
        editValue={values["citizenship"]}
        error={errorMessage(error, "citizenship")}
        handleValues={(newValue: Answers) => setValue("citizenship", newValue)}
        placeholder={translate("global.select.list")}
        toMap={countries}
        disabled={values['citizenship']}
        width={{ width: "41px" }}
      />
      <InputGeneral
        editValue={values["idNumber"]}
        label={translate("global.idCard")}
        setter={(newValue: string) => setValue("idNumber", newValue)}
        name="idNumber"
        disabled={disabled}
        error={errorMessage(error, "idNumber")}
      />
      {extraNationality && (
        <div className="form-nationality-container">
          <SelectGeneral
            className="form-nationality-select"
            isMultiple={false}
            errorType="error"
            label={translate("global.nationality")}
            editValue={values["nationality2"]}
            error={errorMessage(error, "nationality2")}
            handleValues={(newValue: Answers) =>
              setValue("nationality2", newValue)
            }
            disabled={disabled}
            placeholder={translate("global.select.list")}
            toMap={countries}
            width={{ width: "41px" }}
          />
          <MinusIcon
            className="form-nationality-icon"
            colorRect="#ff0000"
            onClick={() => setExtraNationality(false)}
          />
        </div>
      )}
    </>
  );
};

export default FormNationality;
