import React from 'react';
import AllOkBanner from '../components/allOKBanner/AllOkBanner';
import ButtonPrimary from '../components/buttons/ButtonPrimary';
import WhiteButton from '../components/buttons/WhiteButton';
import InputGetLife from '../components/inputGetLife/InputGetLife';
import SelectGetlife from '../components/select/SelectGetlife';
import TextArea from '../components/textArea/TextArea';
import Warning from './../../assets/img/warning.svg';
import './css/incidentsMain.scss';

export default function IncidentsMain(props) {
    const {
        comments,
        handlecomments,
        handleIncidentType,
        handleEmail,
        incidentType,
        isSendIncident,
        submitIncident,
        email,
        translate
    } = props;

    const allStates = [email, incidentType, comments];

    const optionsTickets = [];
    optionsTickets.push({ value: String('PAYMENT'), label: translate("incidents.option.payment") });
    optionsTickets.push({
        value: String('POLICY_MOD'),
        label: translate("incidents.option.policy_mod")
    });
    optionsTickets.push({
        value: String('POLICY_CANC'),
        label: translate("incidents.option.policy_canc")
    });
    optionsTickets.push({ value: String('OTHERS'), label: translate("incidents.option.others") });

    const isButtonDisabled = () => {
        let filtered = false;
        for (let i = 0; i <= allStates.length; i++) {
            if (allStates[i] === false || allStates[i] === '') {
                filtered = true;
            }
        }
        return filtered;
    };

    return (
        <main className="incidentsMain-mainContainer">
            <div className="incidentsMain-container">
                <article className="incidentsMain-articleContainer">
                    <div className="incidentsMain-textImgContainer">
                        <h1>{translate("incidents.dashboard.title")}</h1>
                        <img src={Warning} alt="Notificar incidencia" />
                    </div>

                    <p>
                        {translate("incidents.dashboard.subtitle")}
                    </p>
                    <div className="incidentsMain-whiteButtonContainer">
                        <WhiteButton content={translate("incidents.button.talk")} />
                    </div>
                </article>
                <div className="incidentsMain-formMainContainer">
                    <div className="incidentsMain-formHeader">
                        <p>{translate("incidents.form.title")}</p>
                    </div>
                    <form
                        onSubmit={submitIncident}
                        className="incidentsMain-formBodyMain"
                    >
                        <div className="incidentsMain-formBody">
                            <InputGetLife
                                label={translate("incidents.form.emailLabel")}
                                handleValues={handleEmail}
                                onBlurGreyBackground={true}
                                answer={email !== false ? email : ''}
                            />
                            <SelectGetlife
                                default={incidentType}
                                toMap={optionsTickets}
                                label={translate("incidents.form.incidentLabel")}
                                handleValues={handleIncidentType}
                            />
                            <TextArea
                                label={translate("incidents.form.commentLabel")}
                                handleValues={handlecomments}
                                onBlurGreyBackground={true}
                                answer={comments !== false ? comments : ''}
                            />

                            <ButtonPrimary
                                content={translate("incidents.form.button")}
                                disabled={isButtonDisabled()}
                            />

                            {isSendIncident === true && (
                                <div style={{ marginBottom: '30px' }}>
                                    <AllOkBanner content={translate("incidents.form.send")} />
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </main>
    );
}
